import React, { useState } from 'react'

import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'

import theme from 'styles/muiTheme'

import StockTableRow from './StockTableRow'

export interface Variant {
  id: number
  name: string
  cost: string
  quantity: number
  sold: number
  stockId: number
}

interface StockDetailsTableProps {
  variants: Variant[]
}

const StockDetailsTable: React.FC<StockDetailsTableProps> = ({
  variants: initialVariants,
}) => {
  const [variants, setVariants] = useState<Variant[]>(initialVariants)

  return (
    <TableContainer className="lg:px-6">
      <Table className="rounded-lg bg-white">
        <TableHead>
          <TableRow>
            <TableCell>
              <Box
                component="span"
                className="font-bold"
                sx={{ color: theme.palette.primary.main }}
              >
                VARIANT
              </Box>
            </TableCell>
            <TableCell>
              <Box
                component="span"
                className="font-bold"
                sx={{ color: theme.palette.primary.main }}
              >
                COST PER UNIT
              </Box>
            </TableCell>
            <TableCell>
              <Box
                component="span"
                className="font-bold"
                sx={{ color: theme.palette.primary.main }}
              >
                QUANTITY
              </Box>
            </TableCell>
            <TableCell>
              <Box
                component="span"
                className="font-bold"
                sx={{ color: theme.palette.primary.main }}
              >
                ITEMS SOLD
              </Box>
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {variants.map((variant) => (
            <StockTableRow
              key={variant.stockId}
              variant={variant}
              setVariants={setVariants}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default StockDetailsTable
