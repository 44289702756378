import React, { useEffect, useState } from 'react'

import DeleteIcon from '@mui/icons-material/Delete'
import { IconButton, Link, List, ListItem } from '@mui/material'

import Card from 'components/card'
import FileUpload from 'components/files/FileUpload'
import { deleteFile } from 'services/files'
import { getProduct, updateProduct } from 'services/products'
import { Product } from 'types/product'

interface ProductFilesProps {
  productId?: number
  onFilesAdd?: (files: string[]) => void
}

const fileUrl = `${process.env.REACT_APP_FILE_URL}`

const ProductFiles: React.FC<ProductFilesProps> = ({
  productId,
  onFilesAdd,
}) => {
  const [uploadedFiles, setUpladedFiles] = useState<string[]>([])
  const [productData, setProductData] = useState<Product>()

  let mediaFiles: string[] = []
  if (productData !== undefined) {
    mediaFiles = productData.media.map((m) => m.split('/')[3])
  }
  mediaFiles = [...mediaFiles, ...uploadedFiles]

  useEffect(() => {
    if (uploadedFiles.length === 0) return
    if (productId) {
      updateProductFiles(productId, uploadedFiles)
    }
  }, [uploadedFiles, productId])

  useEffect(() => {
    if (onFilesAdd) onFilesAdd(uploadedFiles)
  }, [uploadedFiles, onFilesAdd])

  const updateProductFiles = async (
    productId: number,
    uploadedFiles: string[]
  ): Promise<void> => {
    const mergeFiles = uploadedFiles.map((file) => `${fileUrl}/${file}`)
    if (productData !== undefined) mergeFiles.push(...productData.media)

    const res = await updateProduct({
      productId,
      dataUpdate: {
        media: mergeFiles,
      },
    })

    if (res.status === 'successful') {
      setProductData(res.data)
      setUpladedFiles([])
    }
  }

  const getProductDetails = async (productId: number): Promise<void> => {
    const res = await getProduct({ id: productId })
    if (res.status === 'successful') {
      setProductData(res.data)
    }
  }

  useEffect(() => {
    if (productId) {
      getProductDetails(productId)
    }
  }, [productId])

  const onDelete = async (fileName: string): Promise<void> => {
    const res = await deleteFile(fileName)
    if (res.status === 'successful') {
      if (productId) {
        const filterFiles = mediaFiles.filter((f) => f !== fileName)
        const result = await updateProduct({
          productId,
          dataUpdate: {
            media: filterFiles.map((file) => `${fileUrl}/${file}`),
          },
        })
        if (result.status === 'successful') {
          setProductData(result.data)
        }
      }

      const currentUploadedFiles = uploadedFiles.filter((f) => f !== fileName)
      setUpladedFiles(currentUploadedFiles)
    }
  }

  return (
    <div className="mb-5">
      <h2 className="text-lg font-semibold ml-4">Pictures and Videos</h2>
      <p className="text-sm text-gray-500 mb-2 ml-4">
        Add Images and Videos of Products
      </p>
      <Card>
        {mediaFiles && mediaFiles.length > 0 && (
          <List>
            {mediaFiles.map((fileName) => (
              <ListItem key={fileName}>
                <Link
                  href={`${process.env.REACT_APP_FILE_URL}/${fileName}`}
                  underline="hover"
                  target="_blank"
                  rel="noopener"
                >
                  {fileName}
                </Link>
                <IconButton
                  aria-label="delete"
                  size="small"
                  onClick={() => onDelete(fileName)}
                  className="ml-4 hover:text-red-600"
                >
                  <DeleteIcon
                    fontSize="inherit"
                    className="text-gray-600 hover:text-red-600"
                  />
                </IconButton>
              </ListItem>
            ))}
          </List>
        )}

        <FileUpload
          onClose={() => {}}
          onSuccess={(file: string) => setUpladedFiles((f) => [...f, file])}
        />
      </Card>
    </div>
  )
}

export default ProductFiles
