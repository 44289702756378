import CheckList from 'pages/dashboard/components/CheckList'

const Home = () => {
  return (
    <>
      <CheckList />
    </>
  )
}

export default Home
