import { FC } from 'react'

import { Box, Button } from '@mui/material'

import Card from 'components/card'

import AddButton from './AddButton'
import TabTitle from './TabTitle'

interface EmptyTabContentProps {
  title: string
  description: string
  addButtonText: string
  importButtonText: string
}
const EmptyTabContent: FC<EmptyTabContentProps> = ({
  title,
  description,
  addButtonText,
  importButtonText,
}) => {
  return (
    <>
      <Box ml={2}>
        <TabTitle title={title} />
      </Box>
      <Card>
        <p className="text-sm text-black-800 mb-4 text-center">{description}</p>
        <div className="flex justify-center space-x-6">
          <Button
            variant="contained"
            sx={{
              backgroundColor: '#E5E7EB',
              '&:hover': {
                backgroundColor: '#D1D5DB',
              },
              color: '#4B5563',
              fontWeight: '600',
              padding: '8px 16px',
              borderRadius: 'md',
              textTransform: 'none',
            }}
          >
            {importButtonText}
          </Button>
          <AddButton buttonText={addButtonText} />
        </div>
      </Card>
    </>
  )
}

export default EmptyTabContent
