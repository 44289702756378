import {
  Box,
  Button,
  Divider,
  List,
  ListItem,
  Paper,
  Typography,
} from '@mui/material'

import { useAuth } from 'hooks/useAuth'

type Store = {
  name: string
  role: string
  storeId: number
}

interface OtherStoreDetailProps {
  stores: Store[]
}

const OtherStoreDetail: React.FC<OtherStoreDetailProps> = ({
  stores,
}): JSX.Element => {
  const { changeOrganization } = useAuth()

  const handlerStoreChange = (id: number): void => {
    changeOrganization(id)
  }
  return (
    <div className="mb-5">
      <h2 className="text-lg font-semibold ml-4">Other Stores</h2>
      {stores && stores?.length !== 0 && (
        <Paper
          className=" m-5"
          sx={{
            backgroundColor: 'secondary.main',
            boxShadow: '0 0 8px 2px rgba(0, 0, 0, 0.1)',
            borderRadius: '5px',
          }}
        >
          <List component="div" disablePadding>
            {stores.map((store, index) => (
              <div key={index}>
                <ListItem
                  className=" px-5 py-2"
                  key={index}
                  sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', sm: 'row' },
                    justifyContent: 'space-between',
                    alignItems: { xs: 'flex-start', sm: 'center' },
                    rowGap: 1,
                  }}
                  disablePadding
                >
                  <Box>
                    <Typography sx={{ minWidth: '150px', fontWeight: '700' }}>
                      {store.name}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography sx={{ fontWeight: '700' }}>
                      {store.role}
                    </Typography>
                  </Box>
                  <Box>
                    <Button
                      variant="outlined"
                      size="small"
                      sx={{
                        backgroundColor: 'white',
                        textTransform: 'none',
                        fontWeight: 'bold',
                        border: 'none',
                        boxShadow: '0 0 8px 2px rgba(0, 0, 0, 0.1)',
                        ':hover': {
                          border: 'none',
                        },
                      }}
                      onClick={() => handlerStoreChange(store.storeId)}
                    >
                      Switch to this store
                    </Button>
                  </Box>
                </ListItem>
                {index < stores.length - 1 && <Divider />}
              </div>
            ))}
          </List>
        </Paper>
      )}
    </div>
  )
}

export default OtherStoreDetail
