import { FC } from 'react'

import { Box, Button, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'

interface AddProductFormStepProps {
  description: string
}

const AddProductFormStep: FC<AddProductFormStepProps> = ({
  description,
}): JSX.Element => {
  const navigate = useNavigate()

  const handleNavigate = (): void => {
    navigate('/dashboard/products/new')
  }

  return (
    <Box
      className="p-4 shadow rounded"
      component="div"
      sx={{ backgroundColor: '#FAFAFA' }}
    >
      <Typography variant="subtitle1" gutterBottom>
        {description}
      </Typography>
      <Button variant="contained" onClick={handleNavigate}>
        Add product
      </Button>
    </Box>
  )
}

export default AddProductFormStep
