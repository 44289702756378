import React, { useState } from 'react'

import { zodResolver } from '@hookform/resolvers/zod'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { IconButton } from '@mui/material'
import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import Snackbar from '@mui/material/Snackbar'
import { useForm, SubmitHandler, Controller } from 'react-hook-form'
import * as z from 'zod'

import theme from 'styles/muiTheme'

import ResendOtp from './ResendOtp'
import { forgotPassword, confirmForgotPassword } from '../../services/auth'

interface ForgotPasswordFormProps {
  onActiveForm: () => void
}

const FormSchema = z.object({
  email: z.string().min(1, 'Email is required').email('Invalid email'),
  otp: z.string().min(6, 'OTP must be 6 digits').max(6, 'OTP must be 6 digits'),
  password: z
    .string()
    .min(8, 'Password must have at least 8 characters')
    .regex(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$*_^&])[A-Za-z\d@#$*_^&]{8,}$/,
      'Password must be 8+ characters with at least one uppercase, one lowercase, one digit, and one special character (@, #, $, *, ^, &)'
    ),
})

const ForgotPasswordForm: React.FC<ForgotPasswordFormProps> = ({
  onActiveForm,
}) => {
  const {
    handleSubmit,
    register,
    watch,
    control,
    getFieldState,
    formState: { errors, isValid, isSubmitting },
  } = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    mode: 'onChange',
    defaultValues: {
      email: '',
      otp: '',
      password: '',
    },
  })

  // Register inputs
  const registerEmail = register('email')
  const registerOTP = register('otp')

  const emailState = getFieldState('email')
  const emailValue = watch('email')
  const [showOtpInput, setShowOtpInput] = useState<boolean>(false)
  const [showPassword, setShowPassword] = useState(false)
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState('')

  const togglePasswordVisibility = (): void => {
    setShowPassword(!showPassword)
  }

  const handleGetOTP = async (): Promise<void> => {
    try {
      const res = await forgotPassword({ email: emailValue })

      if (res.status === 'successful') {
        setShowOtpInput(true)
      }
    } catch (error: unknown) {
      console.error((error as Error).message)
      setSnackbarMessage('User not found or invalid credentials')
      setSnackbarOpen(true)
    }
  }

  const onSubmitForm: SubmitHandler<{
    email: string
    otp: string
    password: string
  }> = async (data) => {
    const { email, otp, password } = data
    try {
      const res = await confirmForgotPassword({
        email,
        confirmationCode: otp,
        password,
      })
      if (res.status === 'successful') {
        onActiveForm()
      }
    } catch (error) {
      console.error('Error confirming forgot password:', error)
    }
  }

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false)
  }

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(onSubmitForm)} className="form-container">
        <section>
          <div className="flex items-center justify-center px-4 py-10 sm:px-6 sm:py-16 lg:px-8 lg:py-24">
            <div className="xl:mx-auto xl:w-full xl:max-w-sm 2xl:max-w-md">
              <h2 className="text-center text-2xl font-bold leading-tight text-black">
                Forgot Password
              </h2>
              <p className="mt-2 text-center text-base text-gray-600">
                Enter your email address associated with this account
              </p>
              <section className="mt-8">
                <div className="space-y-5">
                  <div>
                    <label
                      htmlFor="email"
                      className="text-base font-medium text-gray-900"
                    >
                      {' '}
                      Email address{' '}
                    </label>
                    <div className="mt-2">
                      <input
                        id="email"
                        className="flex bg-white h-10 w-full lg:w-96 rounded-md border border-gray-300 bg-transparent px-3 py-2 text-sm placeholder:text-gray-400 focus:outline-none focus:ring-1 focus:ring-gray-400 focus:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-50"
                        type="email"
                        placeholder="Email"
                        autoComplete="email"
                        onChange={registerEmail.onChange}
                        onBlur={registerEmail.onBlur}
                        name={registerEmail.name}
                        ref={registerEmail.ref}
                      />
                    </div>
                  </div>
                  {!showOtpInput && (
                    <div>
                      <Button
                        type="button"
                        color="primary"
                        variant="contained"
                        sx={{ width: '100%' }}
                        disabled={!emailValue || emailState.invalid}
                        onClick={handleGetOTP}
                      >
                        Get OTP{' '}
                        <ArrowForwardIcon
                          className="ml-2"
                          component={ArrowForwardIcon}
                        />
                      </Button>
                    </div>
                  )}
                  {showOtpInput && (
                    <>
                      <div>
                        <div className="flex items-center justify-between">
                          <label
                            htmlFor="password"
                            className="text-base font-medium text-gray-900"
                          >
                            New Password{' '}
                          </label>
                          <IconButton
                            sx={{ color: theme.palette.text.primary }}
                            onClick={togglePasswordVisibility}
                          >
                            {showPassword ? (
                              <VisibilityOffIcon />
                            ) : (
                              <VisibilityIcon />
                            )}
                          </IconButton>
                        </div>
                        <div className="mt-2">
                          <Controller
                            name="password"
                            control={control}
                            render={({ field }) => (
                              <input
                                value={field.value}
                                onChange={field.onChange}
                                className="flex bg-white h-10 w-full rounded-md border border-gray-300 bg-transparent px-3 py-2 text-sm placeholder:text-gray-400 focus:outline-none focus:ring-1 focus:ring-gray-400 focus:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-50"
                                type={showPassword ? 'text' : 'password'}
                                placeholder="New Password"
                                id="password"
                                autoComplete="new-password"
                              />
                            )}
                          />
                          {errors.password && (
                            <p className=" text-xs text-red-600 bg-red-200 px-2 py-1 rounded mt-1">
                              *{errors.password?.message}
                            </p>
                          )}
                        </div>
                      </div>
                      <div>
                        <label
                          htmlFor="otp"
                          className="text-base font-medium text-gray-900"
                        >
                          {' '}
                          OTP{' '}
                        </label>
                        <div className="mt-2">
                          <input
                            id="otp"
                            className="flex h-10 w-full rounded-md border border-gray-300 bg-transparent px-3 py-2 text-sm placeholder:text-gray-400 focus:outline-none focus:ring-1 focus:ring-gray-400 focus:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-50"
                            type="text"
                            placeholder="OTP"
                            autoComplete="off"
                            maxLength={6}
                            onChange={registerOTP.onChange}
                            onBlur={registerOTP.onBlur}
                            name={registerOTP.name}
                            ref={registerOTP.ref}
                          />
                          {errors.otp && (
                            <p className=" text-xs text-red-600 bg-red-200 px-2 py-1 rounded mt-1">
                              *{errors.otp?.message}
                            </p>
                          )}
                        </div>
                      </div>
                      <ResendOtp email={emailValue} />
                      <div>
                        <Button
                          type="submit"
                          color="primary"
                          variant="contained"
                          sx={{ width: '100%' }}
                          disabled={!isValid || isSubmitting}
                        >
                          {isSubmitting ? 'Verifying' : 'Verify OTP'}
                          <ArrowForwardIcon
                            className="ml-2"
                            component={ArrowForwardIcon}
                          />
                        </Button>
                      </div>
                    </>
                  )}
                </div>
              </section>
            </div>
          </div>
        </section>
      </form>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity="error">
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </React.Fragment>
  )
}

export default ForgotPasswordForm
