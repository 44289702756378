import React from 'react'

import { Container, Grid, Typography, Button, Box } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useNavigate } from 'react-router-dom'

const FooterContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
}))

const FooterGrid = styled(Grid)(({ theme }) => ({
  justifyContent: 'space-between',
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    textAlign: 'center',
  },
}))

const FooterButton = styled(Button)(({ theme }) => ({
  color: theme.palette.text.secondary,
  textTransform: 'none',
  marginLeft: theme.spacing(2),
  [theme.breakpoints.down('md')]: {
    marginBottom: theme.spacing(1),
    marginRight: 0,
  },
}))

const Footer: React.FC = () => {
  const navigate = useNavigate()
  const handlePrivacyNavigate = (): void => {
    navigate('/privacy-policy')
  }
  const handleTermsNavigate = (): void => {
    navigate('/terms-of-service')
  }
  return (
    <FooterContainer>
      <Container>
        <FooterGrid container>
          <Grid item>
            <Typography variant="body1" color="textSecondary">
              © 2024 Cartcrest E-Market Solutions Private Limited
            </Typography>
          </Grid>
          <Grid item>
            <FooterButton onClick={handleTermsNavigate}>
              Terms of Service
            </FooterButton>
            <FooterButton onClick={handlePrivacyNavigate}>
              Privacy Policy
            </FooterButton>
          </Grid>
        </FooterGrid>
      </Container>
    </FooterContainer>
  )
}

export default Footer
