import React from 'react'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Grid,
  Box,
} from '@mui/material'
import { styled } from '@mui/material/styles'

const FAQContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  // marginTop: theme.spacing(4),
  marginBottom: theme.spacing(4),
}))

const faqs = [
  {
    question: 'What is Haulistic?',
    answer:
      'Haulistic is a platform designed to help new sellers on Instagram easily set up and manage their online stores. It provides tools for product listing, inventory management, payment processing, delivery integration, and sales analytics, all within a user-friendly dashboard.',
  },
  {
    question: 'How does Haulistic integrate with Instagram?',
    answer:
      'Haulistic connects directly to your Instagram account, allowing you to seamlessly import your products, manage sales, and interact with customers. Our platform enhances your Instagram shopping experience by providing advanced tools to manage your online store.',
  },
  {
    question: 'Do I need any technical knowledge to use Haulistic?',
    answer:
      'No technical expertise is required to use Haulistic. Our platform is designed to be user-friendly, with intuitive interfaces that guide you through each step of setting up and managing your store.',
  },
  {
    question: 'Can I customize my online store on Haulistic?',
    answer:
      "Yes, Haulistic allows you to customize your store's appearance and functionality to match your brand's identity. You can choose from various templates and design elements to create a unique online presence.",
  },
  {
    question: 'How does Haulistic handle payments?',
    answer:
      'Haulistic integrates with popular payment gateways to provide a secure and smooth checkout experience for your customers. You can choose from multiple payment options to suit your business needs.',
  },
  {
    question: 'Is Haulistic suitable for small businesses?',
    answer:
      'Absolutely! Haulistic is specifically designed with small and growing businesses in mind. Our tools help streamline operations, allowing you to focus on what matters most—growing your business.',
  },
  {
    question: 'How does the inventory management system work?',
    answer:
      "Haulistic's inventory management system helps you keep track of stock levels, set reorder alerts, and manage product variations. This ensures that you never run out of stock and can meet customer demand efficiently.",
  },
  {
    question: 'What kind of analytics does Haulistic provide?',
    answer:
      'Haulistic offers detailed analytics on your sales performance, customer behavior, and product trends. These insights help you make informed decisions to optimize your store’s performance and grow your business.',
  },
  {
    question: 'Can I offer discounts and promotions through Haulistic?',
    answer:
      'Yes, Haulistic allows you to create and manage discounts, promotions, and special offers easily. You can customize these promotions to target specific customer segments or boost sales during special events.',
  },
  {
    question: 'How do I request early access to Haulistic?',
    answer:
      "You can request early access by filling out the form on our website. Provide your name, business details, email, and Instagram page (if applicable), and we'll get back to you with further details.",
  },
  {
    question: 'What support is available for Haulistic users?',
    answer:
      'Haulistic provides comprehensive support through our help center, which includes tutorials, guides, and FAQs. You can also reach out to our customer support team for personalized assistance.',
  },
  {
    question: 'How much does Haulistic cost?',
    answer:
      'Haulistic offers various pricing plans to suit different business needs. Pricing details will be available closer to the official launch. Early access users may benefit from special introductory offers.',
  },
]

const FAQSection: React.FC = () => {
  return (
    <FAQContainer>
      <Typography
        variant="h4"
        align="center"
        gutterBottom
        sx={{
          mb: { xs: 4, sm: 6 },
          fontWeight: 'bold',
          color: 'text.primary',
        }}
      >
        Frequently Asked Questions
      </Typography>

      <Grid container spacing={4}>
        {faqs.map((faq, index) => (
          <Grid item xs={12} md={6} key={index}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${index}-content`}
                id={`panel${index}-header`}
              >
                <Typography>{faq.question}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>{faq.answer}</Typography>
              </AccordionDetails>
            </Accordion>
          </Grid>
        ))}
      </Grid>
    </FAQContainer>
  )
}

export default FAQSection
