import { forwardRef, useEffect } from 'react'

import TextField from '@mui/material/TextField'

type AddressFormatType = {
  city: string
  state: string
  country: string
  postalCode: string
}
interface CityInputProps {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  onBlur: (event: React.FocusEvent<HTMLInputElement>) => void
  name: string
  value: string
  inputRef: React.RefObject<HTMLInputElement>
  onCitySelect?: (arg: AddressFormatType | null) => void
  restrictTo?: string
  error?: boolean
  helperText?: string
}

const setupAutocomplete = (
  inputRef: React.RefObject<HTMLInputElement>,
  onCitySelect?: (arg: AddressFormatType | null) => void,
  restrictTo?: string
): void => {
  if (!inputRef.current) return
  const autocomplete = new window.google.maps.places.Autocomplete(
    inputRef.current,
    {
      types: ['(cities)'],
      componentRestrictions: {
        country: restrictTo ? [restrictTo] : ['us', 'in'],
      }, // specify the country
      fields: ['address_components'],
    }
  )

  autocomplete.addListener('place_changed', () => {
    const place = autocomplete.getPlace()

    if (!onCitySelect) return

    const data = addressFormat(place.address_components)
    onCitySelect(data)
  })
}

const CityInput = forwardRef<HTMLDivElement, CityInputProps>(
  (
    {
      inputRef,
      onCitySelect,
      restrictTo,
      error,
      onChange,
      onBlur,
      name,
      value,
      helperText,
    },
    ref
  ) => {
    useEffect(() => {
      setupAutocomplete(inputRef, onCitySelect, restrictTo)
    }, [inputRef, onCitySelect, restrictTo])

    return (
      <TextField
        onChange={onChange}
        onBlur={onBlur}
        name={name}
        value={value}
        ref={ref}
        fullWidth
        inputRef={inputRef}
        inputProps={{ style: { backgroundColor: 'white' } }}
        size="small"
        sx={{ marginTop: 0 }}
        error={error}
        placeholder="Enter a city name"
        helperText={helperText}
      />
    )
  }
)

function addressFormat(
  address_components: google.maps.GeocoderAddressComponent[] | undefined
): AddressFormatType | null {
  if (!address_components) return null

  let city: string = ''
  let state: string = ''
  let country: string = ''
  let postalCode: string = ''

  for (const component of address_components as google.maps.GeocoderAddressComponent[]) {
    const componentType = component.types[0]

    switch (componentType) {
      case 'administrative_area_level_3': {
        city = component.long_name
        break
      }

      case 'administrative_area_level_4': {
        city = component.long_name
        break
      }

      case 'locality': {
        city = component.long_name
        break
      }

      case 'administrative_area_level_1': {
        state += component.long_name
        break
      }

      case 'country': {
        country += component.long_name
        break
      }
      case 'postal_code': {
        postalCode = `${component.long_name}`
        break
      }
    }
  }
  const data = { city, state, country, postalCode }

  return data
}

CityInput.displayName = 'CityInput'

export default CityInput
