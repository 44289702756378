import { createTheme } from '@mui/material/styles'

declare module '@mui/material/styles' {
  interface Palette {
    accents?: {
      primaryAccent: React.CSSProperties['color']
      secondaryAccent: React.CSSProperties['color']
      grayAccent?: React.CSSProperties['color']
      darkGrayAccent?: React.CSSProperties['color']
      lightGrayAccent?: React.CSSProperties['color']
    }
  }
  interface PaletteOptions {
    accents?: {
      primaryAccent?: React.CSSProperties['color']
      secondaryAccent?: React.CSSProperties['color']
      grayAccent?: React.CSSProperties['color']
      darkGrayAccent?: React.CSSProperties['color']
      lightGrayAccent?: React.CSSProperties['color']
    }
  }
}

const theme = createTheme({
  typography: {
    fontFamily: '"Lato", "Helvetica", "Arial", sans-serif',
  },
  palette: {
    primary: {
      main: '#00897B', // Main Primary Color (Teal Green)
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#F4F7F6', // Secondary Primary Color (Whisper Gray)
      contrastText: '#333333',
    },
    error: {
      main: '#DC143C', // Error (Crimson red)
    },
    warning: {
      main: '#E2B93B', // Warning (Saffron)
    },
    info: {
      main: '#5D99C6', // Soft Sky Blue as secondary accent color for info-like contexts
    },
    success: {
      main: '#27AE60', // Success (Jungle Green)
    },
    action: {
      active: '#FF6B6B', // Primary Accent Color (Light Coral) for active states
      hover: '#FF6B6B', // Optionally, use the same for hover states for a consistent accent color experience
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
    text: {
      primary: '#333333', // Text Color (Davy's Gray)
      secondary: '#666666', // Secondary Text/Icon Color (Dim Gray)
    },
    background: {
      default: '#ffffff',
      paper: '#ffffff',
    },
    // Custom accent colors if needed for more specific use-cases
    accents: {
      primaryAccent: '#FF6B6B', // Light Coral
      secondaryAccent: '#5D99C6', // Soft Sky Blue
      grayAccent: '#f8f8f8',
      darkGrayAccent: '#e0e0e0',
      lightGrayAccent: 'rgba(224, 224, 224,0.6)',
      // Additional custom colors can be defined here
    },
  },
})

// Example of accessing a custom accent color in a component
// const customAccentColor = theme.palette.accents.primaryAccent;

export default theme
