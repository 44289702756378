import React, { useState } from 'react'

import { TextField, Chip, Stack, Box } from '@mui/material'

const isValidInput = (input: string): boolean => {
  return /^[a-zA-Z0-9]+(?:[_-][a-zA-Z0-9]+)*$/.test(input)
}

interface MultiSelectTagsProps {
  tags: string[]
  onTagsChange: (tags: string[]) => void
}

const MultiSelectTags = ({
  tags,
  onTagsChange,
}: MultiSelectTagsProps): JSX.Element => {
  const [inputValue, setInputValue] = useState<string>('')
  const [inputError, setInputError] = useState<string>('')

  const handleAddTag = (tag: string) => {
    if (tag && !tags.includes(tag)) {
      const updatedTags = [...tags, tag]
      onTagsChange(updatedTags)
      setInputValue('')
    }
  }

  const handleDeleteTag = (tagToDelete: string) => {
    const updatedTags = tags.filter((tag) => tag !== tagToDelete)
    onTagsChange(updatedTags)
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.trim()
    setInputValue(value)

    if (!isValidInput(value)) {
      setInputError(
        '*Must consist of letters, numbers, hyphens, and underscores'
      )
      return
    } else {
      setInputError('')
    }
    setInputValue(event.target.value)
  }

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      handleAddTag(inputValue)
    }
  }

  return (
    <>
      <Box>
        <TextField
          fullWidth
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          variant="standard"
          label="Add Tag"
          placeholder="Type and press Enter"
          size="small"
          sx={{ maxWidth: 200 }} // Adjust size as needed
          error={Boolean(inputError !== '')}
        />
        {inputError !== '' && (
          <p className=" text-red-500 text-xs pt-1">{inputError}</p>
        )}
      </Box>

      <Stack
        direction="row"
        spacing={1}
        rowGap={1}
        flexWrap="wrap"
        alignItems="center"
        mt={2}
      >
        {tags.map((tag, index) => (
          <Chip
            key={index}
            label={tag}
            onDelete={() => handleDeleteTag(tag)}
            variant="outlined"
          />
        ))}
      </Stack>
    </>
  )
}

export default MultiSelectTags
