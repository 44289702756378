import Cookies from 'js-cookie'
import { jwtDecode } from 'jwt-decode'

import { sendRefreshToken } from 'services/auth'
import { AuthData } from 'types/auth'

export const setAuthCookies = (data: AuthData): void => {
  // js-cookie expects the expires option to be in days. Convert seconds to days.
  const expiresInDays = data.ExpiresIn / (60 * 60 * 24)

  Cookies.set('AccessToken', data.AccessToken, { expires: expiresInDays })
  Cookies.set('RefreshToken', data.RefreshToken, { expires: 30 })
  Cookies.set('IdToken', data.IdToken, { expires: expiresInDays })
  Cookies.set('TokenType', data.TokenType, { expires: expiresInDays })
}

export const getAuthCookie = (name: string): string | null => {
  return Cookies.get(name) ?? null
}

export const clearAuthCookies = (): void => {
  Cookies.remove('AccessToken')
  Cookies.remove('RefreshToken')
  Cookies.remove('IdToken')
  Cookies.remove('TokenType')
}

export const isTokenExpired = (tokenName: string): boolean => {
  const token = getAuthCookie(tokenName)

  //Check accessToken is null or not.
  if (!token) return true

  const pay = jwtDecode(token)
  const { exp } = pay
  const expired = Date.now() >= (exp ?? 0) * 1000
  return expired
}

export const updateTokens = async (): Promise<null | {
  AccessToken: string
  IdToken: string
}> => {
  const refreshToken = getAuthCookie('RefreshToken')

  //Check refreshToken empty or not.
  if (!refreshToken) return null

  try {
    const data = await sendRefreshToken(refreshToken)

    const expiresInDays = data.ExpiresIn / (60 * 60 * 24)

    Cookies.set('AccessToken', data.AccessToken, { expires: expiresInDays })
    Cookies.set('IdToken', data.IdToken, { expires: expiresInDays })
    Cookies.set('TokenType', data.TokenType, { expires: expiresInDays })

    return { AccessToken: data.AccessToken, IdToken: data.IdToken }
  } catch (error) {
    //if any error occur returning null, so user can login again (e.g. expired refresh token).
    return null
  }
}

export const getToken = async (
  token: string = 'IdToken'
): Promise<string | null> => {
  if (isTokenExpired(token)) {
    const updatedToken = await updateTokens()
    if (!updatedToken) {
      clearAuthCookies()
      if (token === 'IdToken') window.location.replace('/signin')
      return null
    }
    return token === 'IdToken' ? updatedToken.IdToken : updatedToken.AccessToken
  }

  if (token === 'AccessToken') {
    return getAuthCookie(token)
  }

  return getAuthCookie(token)
}
