import { Country, IState, State } from 'country-state-city'

export type CountriesListParams = {
  flagIcon: string
  name: string
  phoneCode: string
  isoCode: string
}

export const showCountries = ['IN', 'US', 'GB']

const countryList = Country.getAllCountries()

export const getCountriesList = (): CountriesListParams[] => {
  return countryList.map((data) => {
    return {
      flagIcon: data.flag,
      isoCode: data.isoCode,
      name: data.name,
      phoneCode: `${data.phonecode.startsWith('+') ? data.phonecode : `+${data.phonecode}`}`,
    }
  })
}

export const getStatesOfCountry = (countryCode: string = ''): IState[] =>
  State.getStatesOfCountry(countryCode)

export const getCountryCode = (name: string): string | undefined =>
  countryList.find((country) => country.name === name)?.isoCode

export const getRestrictedCountriesList = (): CountriesListParams[] => {
  const countriesList = getCountriesList()
  return countriesList.filter((obj) => showCountries.includes(obj.isoCode))
}
