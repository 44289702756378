import { useContext } from 'react'

import { Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import { TemplateContentContext } from 'contexts/template/templateContentContext'

const NewEditorBox = () => {
  const navigate = useNavigate()
  const { setTemplateContent, setTemplateName } = useContext(
    TemplateContentContext
  )

  const handleClick = () => {
    setTemplateContent('')
    setTemplateName('')
    navigate('/dashboard/editor?isDefault=true')
  }

  return (
    <Button
      variant="contained"
      size="small"
      sx={{ textTransform: 'none' }}
      onClick={handleClick}
    >
      Create Template
    </Button>
  )
}

export default NewEditorBox
