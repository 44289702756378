import React, { useContext } from 'react'

import { TableCell } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import { TemplateContentContext } from 'contexts/template/templateContentContext'
import { getTemplateContent } from 'services/emailTemplate'
import { Template } from 'types/template'

const PredefinedTemplateBox = ({
  template,
  loading,
  setLoading,
  setError,
}: {
  template: Template
  loading: boolean
  setLoading: (loading: boolean) => void
  setError: (error: boolean) => void
}) => {
  const navigate = useNavigate()
  const { setTemplateContent, setTemplateName } = useContext(
    TemplateContentContext
  )

  const handleClick = async () => {
    setLoading(true)
    try {
      const htmlContent: string = await getTemplateContent(template)
      setTemplateContent(htmlContent)
      setTemplateName(template.templateName)
      const queryParams = new URLSearchParams({
        isDefault: template.isDefault.toString(),
      })
      if (!template.isDefault) {
        queryParams.append('templateId', template.id.toString())
      }
      navigate(`/dashboard/editor?${queryParams.toString()}`)
    } catch (error) {
      console.error('Error fetching template content:', error)
      setError(true)
    } finally {
      setLoading(false)
    }
  }

  return (
    <TableCell
      component="th"
      scope="row"
      onClick={handleClick}
      sx={{
        cursor: 'pointer',
        transition: 'background-color 0.3s',
        '&:hover': {
          backgroundColor: '#e0e0e0',
          opacity: 0.6, // Light grey color on hover
          textDecoration: 'underline',
        },
        opacity: loading ? 0.6 : 1, // Lower opacity when loading
        position: 'relative',
      }}
    >
      {template.templateName}
    </TableCell>
  )
}

export default PredefinedTemplateBox
