import React, { useContext, useEffect, useRef, useState } from 'react'

import { TextField, Button, Box, Snackbar, Alert } from '@mui/material'
import grapesjs from 'grapesjs'
import type { Editor } from 'grapesjs'
import 'grapesjs/dist/css/grapes.min.css'
import plugin from 'grapesjs-preset-newsletter'
import { useLocation, useNavigate } from 'react-router-dom'

import { TemplateContentContext } from 'contexts/template/templateContentContext'
import { TemplateContext } from 'contexts/template/templateContext'
import { useAuth } from 'hooks/useAuth'

const EditorPage: React.FC = () => {
  const { organizationId } = useAuth()
  const editorRef = useRef<Editor | null>(null)
  const { templateContent, templateName, setTemplateName } = useContext(
    TemplateContentContext
  )
  const { saveCurrTemplate, updateCurrTemplate } = useContext(TemplateContext)
  const [isEditingName, setIsEditingName] = useState(false)

  const [error, setError] = useState('')
  const navigate = useNavigate()
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const isDefault = params.get('isDefault') === 'true'
  const templateId = params.get('templateId')

  useEffect(() => {
    editorRef.current = grapesjs.init({
      container: '#gjs',
      plugins: [plugin],
      pluginsOpts: {
        plugin: {
          /* options */
        },
      },
      fromElement: false,
      height: 'calc(100vh - 64px)',
      width: 'auto',
      storageManager: false,
      components: templateContent || '',
      style: '',
    })
    return () => {
      if (editorRef.current) {
        editorRef.current.destroy()
      }
    }
  }, [templateContent])

  const handleSaveorUpdateTemplate = async () => {
    if (!templateName) {
      setError('Template name is required')
      return
    }

    if (editorRef.current) {
      const htmlContent = editorRef.current.getHtml()
      const cssContent = editorRef.current.getCss()
      const combinedContent = `<style>${cssContent}</style>${htmlContent}`
      try {
        if (isDefault) {
          await saveCurrTemplate(
            combinedContent,
            templateName,
            organizationId as number
          )
        } else if (templateId) {
          await updateCurrTemplate(templateId, combinedContent, templateName)
        }
        navigate('/dashboard/marketing/email')
      } catch (error) {
        console.error('Error saving template:', error)
        setError('Error saving template. Please try again later.')
      }
    }
  }

  const handleCancel = () => {
    navigate('/dashboard/marketing/email')
  }

  return (
    <Box
      sx={{
        height: '85.5vh',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          paddingBottom: 0.9,
          justifyContent: 'space-between',
        }}
      >
        {isEditingName ? (
          <TextField
            variant="standard"
            size="small"
            value={templateName}
            placeholder="Enter template name"
            onChange={(e) => setTemplateName(e.target.value)}
            onBlur={() => setIsEditingName(false)}
            autoFocus
          />
        ) : (
          <h2
            onDoubleClick={() => setIsEditingName(true)}
            style={{
              cursor: 'pointer',
              fontWeight: 'bold',
            }}
          >
            {templateName || 'Untitled Template'}
          </h2>
        )}
        <Box>
          <Button
            variant="outlined"
            size="small"
            onClick={handleCancel}
            sx={{ marginRight: 0.9, textTransform: 'none' }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            size="small"
            onClick={handleSaveorUpdateTemplate}
            sx={{ marginRight: 0.9, textTransform: 'none' }}
          >
            Save Changes
          </Button>
        </Box>
      </Box>
      <Box
        sx={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          border: '12px solid #e0e0e0',
          borderRadius: '4px',
          overflow: 'hidden',
          margin: 0.6,
          boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
        }}
      >
        <div id="gjs" style={{ flexGrow: 1 }}></div>
      </Box>
      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={() => setError('')}
      >
        <Alert onClose={() => setError('')} severity="error">
          {error}
        </Alert>
      </Snackbar>
    </Box>
  )
}

export default EditorPage
