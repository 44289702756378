import React, { useState } from 'react'

import { Button } from '@mui/material'
import { SubmitHandler, Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { useAuth } from 'hooks/useAuth'

import { resendCode } from '../../services/auth'

interface FormValues {
  email: string
  password: string
  confirmationCode: string
}

interface SignUpConfirmationComponentProps {
  email: string
  password: string
}

const SignUpConfirmationForm: React.FC<SignUpConfirmationComponentProps> = ({
  email,
  password,
}) => {
  const { confirmSignUp } = useAuth()
  const navigate = useNavigate()
  const { control, handleSubmit, formState } = useForm<FormValues>({
    defaultValues: {
      email: email,
      password: password,
      confirmationCode: '',
    },
  })

  const [resendCodeSuccess, setResendCodeSuccess] = useState(false)
  const [error, setError] = useState<string | null>(null)

  const onSubmit: SubmitHandler<FormValues> = async (values) => {
    try {
      confirmSignUp(values.email, values.password, values.confirmationCode)
      navigate('/dashboard/home')
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setError(
        error.message || 'An error occurred while confirming registration.'
      )
    }
  }

  const handleResendCode = async (): Promise<void> => {
    try {
      await resendCode({ email })
      setResendCodeSuccess(true)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setError(
        error.message || 'An error occurred while resending confirmation code.'
      )
    }
  }

  return (
    <section>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex items-center justify-center px-4 py-10 sm:px-6 sm:py-16 lg:px-4 lg:py-24">
          <div className="xl:mx-auto xl:w-full xl:max-w-sm 2xl:max-w-md">
            <h2 className="text-center text-2xl font-bold leading-tight text-black">
              Sign up Confirmation
            </h2>
            {error && (
              <div className="mt-4 text-red-600 text-center">{error}</div>
            )}
            <div className="mt-8 space-y-5">
              {/* Email */}
              <div>
                <label
                  htmlFor="email"
                  className="text-base font-medium text-gray-900"
                >
                  Email address
                </label>
                <div className="mt-2">
                  <Controller
                    name="email"
                    control={control}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <input
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        ref={ref}
                        className="flex bg-white h-10 w-full rounded-md border border-gray-300 bg-transparent px-3 py-2 text-sm placeholder-text-gray-400 focus:outline-none focus:ring-1 focus:ring-gray-400 focus:ring-offset-1"
                        type="email"
                        placeholder="Email"
                        id="email"
                        disabled
                      />
                    )}
                  />
                </div>
              </div>
              {/* Confirmation Code */}
              <div>
                <label
                  htmlFor="confirmationCode"
                  className="text-base font-medium text-gray-900"
                >
                  Confirmation Code
                </label>
                <div className="mt-2">
                  <Controller
                    name="confirmationCode"
                    control={control}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <input
                        onChange={(e) => {
                          onChange(e.target.value.replace(/\s+/g, ''))
                        }}
                        onBlur={onBlur}
                        value={value}
                        ref={ref}
                        className="flex bg-white h-10 w-full rounded-md border border-gray-300 bg-transparent px-3 py-2 text-sm placeholder:text-gray-400 focus:outline-none focus:ring-1 focus:ring-gray-400 focus:ring-offset-1"
                        type="text"
                        placeholder="Confirmation Code"
                        id="confirmationCode"
                        autoComplete="off"
                      />
                    )}
                  />
                </div>
              </div>
              <div className="flex justify-between">
                <Button
                  variant="text"
                  onClick={handleResendCode}
                  disabled={resendCodeSuccess || formState.isSubmitting}
                  className="mr-5"
                >
                  {resendCodeSuccess ? 'Code Resent' : 'Resend Code'}{' '}
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  disabled={formState.isSubmitting}
                >
                  Confirm
                </Button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </section>
  )
}

export default SignUpConfirmationForm
