import { FC, useState } from 'react'

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
} from '@mui/material'

import SubTabHeader from 'pages/dashboard/components/SubTabHeader'

import ProductFiles from './Files'

interface FilesDetailsProps {
  media?: string[]
  productId?: number
  onUpdate?: () => void
}

const FilesDetails: FC<FilesDetailsProps> = ({ media, productId }) => {
  const [openDialog, setOpenDialog] = useState(false)
  // const [updatedFiles, setFilesUpdated] = useState<string[]>([])

  const handleEdit = (): void => {
    setOpenDialog(true)
  }
  const handleCloseDialog = (): void => {
    setOpenDialog(false)
  }

  return (
    <div className="mb-5">
      <SubTabHeader
        title="Picture & Videos"
        buttonText="Edit"
        onClick={handleEdit}
      />
      <Box
        className="my-2 whitespace-nowrap overflow-x-scroll"
        sx={{ scrollbarWidth: 'none' }}
      >
        {media &&
          media.map((url, index) => (
            <Box
              key={index}
              className=" h-52 w-36 rounded inline-block mr-2 overflow-hidden"
            >
              <img src={url} className=" w-full h-52 block object-contain" />
            </Box>
          ))}
      </Box>
      {openDialog && (
        <Dialog open onClose={handleCloseDialog} maxWidth={'md'} fullWidth>
          <DialogContent>
            <Box>
              <ProductFiles productId={productId} />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog}>Cancel</Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  )
}

export default FilesDetails
