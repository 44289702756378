import { FC, useEffect, useState } from 'react'

import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'

import CustomTextField from 'pages/dashboard/components/CustomTextField'

type VariantType = {
  id?: number
  type: string
  name: string
  price: string | number
  discountedPrice: string | number
}

interface VariantsTableProps {
  type: string
  edit?: boolean
  columns: string[]
  rowData: VariantType[]
  onRowRemove?: (v: VariantType[]) => void
  onRowAdd?: (v: VariantType[]) => void
}

const VariantsTable: FC<VariantsTableProps> = ({
  type,
  edit,
  columns,
  rowData,
  onRowRemove,
  onRowAdd,
}) => {
  const [rows, setRows] = useState<VariantType[]>([])
  const [error, setError] = useState({
    idx: -1,
    fieldName: '',
    message: '',
  })

  useEffect(() => {
    setRows(rowData)
  }, [rowData])

  const handleRowAdd = () => {
    const updatedArray = [
      ...rows,
      {
        type: type,
        name: '',
        price: '',
        discountedPrice: '',
      },
    ]
    setRows(updatedArray)
  }

  const handleRowRemove = (index: number): void => {
    const updatedArray = rows.filter((v, idx) => idx !== index)
    setRows(updatedArray)
    onRowRemove?.(updatedArray)
  }

  const handleNameChange = (value: string, index: number): void => {
    const data = rows.map((v, idx) => {
      if (idx === index) {
        return { ...v, name: value }
      }
      return v
    })
    setRows(data)
    onRowAdd?.(data)
  }

  const handlePriceChange = (value: string, index: number): void => {
    const data = rows.map((v, idx) => {
      if (idx === index) {
        return { ...v, price: Number(value) }
      }
      return v
    })
    setRows(data)
    onRowAdd?.(data)
  }

  const handleDiscountedPriceChange = (value: string, index: number): void => {
    const data = rows.map((v, idx) => {
      if (idx === index) {
        return { ...v, discountedPrice: Number(value) }
      }
      return v
    })

    const variantValues = data.at(index)
    if (
      variantValues &&
      +variantValues.price < +variantValues.discountedPrice
    ) {
      setError({
        idx: index,
        fieldName: 'discountedPrice' + index,
        message: 'should be less than price.',
      })
    }
    setRows(data)
    onRowAdd?.(data)
  }

  const handleBlur = (value: string, index: number, fieldName: string) => {
    const data = rows.at(index)

    if (data && value === '') {
      setError({
        idx: index,
        fieldName: fieldName + index,
        message: fieldName + ' is required.',
      })
    } else {
      setError({
        idx: -1,
        fieldName: '',
        message: '',
      })
    }
  }

  return (
    <>
      <TableContainer component={Box} ml={2} maxWidth={'95%'}>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((colName, index) => (
                <TableCell
                  key={index}
                  sx={{
                    border: 'none',
                    p: 0,
                    fontSize: '0.8rem',
                    color: 'text.secondary',
                    fontWeight: '600',
                    width: 'calc(100% / 3)',
                  }}
                >
                  {colName}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {!edit &&
              rows.map((variant, index) => (
                <TableRow key={index}>
                  <TableCell sx={{ px: 0, fontWeight: 700, py: 1 }}>
                    {variant.name}
                  </TableCell>
                  <TableCell sx={{ px: 0, fontWeight: 700, py: 1 }}>
                    ₹{variant.price}
                  </TableCell>
                  <TableCell sx={{ px: 0, fontWeight: 700, py: 1 }}>
                    ₹{variant.discountedPrice}
                  </TableCell>
                </TableRow>
              ))}
            {edit && (
              <>
                {rows.map((variant, index) => (
                  <TableRow key={index}>
                    <TableCell sx={{ p: 0, fontWeight: 700, pr: 2, pt: 1 }}>
                      <CustomTextField
                        name={`name${index}`}
                        value={variant.name}
                        onChange={(e) =>
                          handleNameChange(e.target.value, index)
                        }
                        onBlur={(e) =>
                          handleBlur(e.target.value, index, 'name')
                        }
                        error={Boolean(error.fieldName === `name${index}`)}
                        helperText={
                          error.fieldName === `name${index}` && error.message
                        }
                      />
                    </TableCell>
                    <TableCell sx={{ p: 0, fontWeight: 700, pr: 2, pt: 1 }}>
                      <CustomTextField
                        type="number"
                        name={`price` + index}
                        value={variant.price.toString()}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">₹</InputAdornment>
                          ),
                        }}
                        onChange={(e) =>
                          handlePriceChange(e.target.value, index)
                        }
                        onBlur={(e) =>
                          handleBlur(e.target.value, index, 'price')
                        }
                        error={Boolean(error.fieldName === `price${index}`)}
                        helperText={
                          error.fieldName === `price${index}` && error.message
                        }
                      />
                    </TableCell>
                    <TableCell sx={{ p: 0, fontWeight: 700, pr: 2, pt: 1 }}>
                      <CustomTextField
                        type="number"
                        name={`discountedPrice` + index}
                        value={variant.discountedPrice.toString()}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">₹</InputAdornment>
                          ),
                        }}
                        onChange={(e) =>
                          handleDiscountedPriceChange(e.target.value, index)
                        }
                        onBlur={(e) =>
                          handleBlur(e.target.value, index, 'discountedPrice')
                        }
                        error={Boolean(
                          error.fieldName === `discountedPrice${index}`
                        )}
                        helperText={
                          error.fieldName === `discountedPrice${index}` &&
                          error.message
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <IconButton onClick={() => handleRowRemove(index)}>
                        <RemoveCircleOutlineIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </>
            )}
          </TableBody>
        </Table>
        {edit && (
          <Button sx={{ fontSize: '0.8rem', mt: 1 }} onClick={handleRowAdd}>
            + Add {type} option
          </Button>
        )}
      </TableContainer>
    </>
  )
}

export default VariantsTable
