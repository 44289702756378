import { createContext, useState, ReactNode } from 'react'

import { TemplateContentContextType } from 'types/template'

const defaultContext: TemplateContentContextType = {
  templateName: '',
  setTemplateName: () => {},
  templateContent: '',
  setTemplateContent: () => {},
}

export const TemplateContentContext =
  createContext<TemplateContentContextType>(defaultContext)

export const TemplateContentProvider = ({
  children,
}: {
  children: ReactNode
}) => {
  const [templateName, setTemplateName] = useState('')
  const [templateContent, setTemplateContent] = useState<string>('')

  return (
    <TemplateContentContext.Provider
      value={{
        templateContent,
        setTemplateContent,
        templateName,
        setTemplateName,
      }}
    >
      {children}
    </TemplateContentContext.Provider>
  )
}
