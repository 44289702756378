import { Template } from 'types/template'

export const defaultTemplates: Template[] = [
  {
    id: 1,
    templateName: 'Basic-NewLetter',
    isDefault: true,
    updatedAt: new Date(),
  },
  {
    id: 2,
    templateName: 'Promotional-Email',
    isDefault: true,
    updatedAt: new Date(),
  },
]
