import { ChangeEvent, FC, useState } from 'react'

import DeleteIcon from '@mui/icons-material/Delete'
import { Box, Grid, IconButton, Link, Typography } from '@mui/material'

import Card from 'components/card'
import Label from 'components/forms/Label'
import { useAuth } from 'hooks/useAuth'
import InputFileUpload from 'pages/dashboard/components/InputFileUpload'
import { deleteFile, getPresignedUrl, uploadFile } from 'services/files'
import { updateOrganization } from 'services/organization'
import { Organization } from 'services/organization/index.types'
import { validateFile } from 'utils/validation'

interface BrandingProps {
  icon?: string
  logo?: string
  coverImage?: string
  onUpdate?: (org?: Organization) => void
}

const fileUrl = process.env.REACT_APP_FILE_URL

const Branding: FC<BrandingProps> = ({
  icon,
  logo,
  coverImage,
  onUpdate,
}): JSX.Element => {
  const { organizationId } = useAuth()
  const [error, setError] = useState<{ type: string; message: string }>()
  const [uploadProgress, setUploadProgress] = useState({
    field: '',
    fileName: '',
    progress: 0,
  })
  const [isUploading, setIsUploading] = useState(false)

  const handleFileChange = async (
    e: ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    if (!e.target.files) return

    const file = e.target.files[0]
    const type = e.target.name
    const validate = await validateFile(file, type)

    if (!validate.valid)
      return setError({ type: type, message: validate.error })

    setIsUploading(true)
    const signedUrl = await getPresignedUrl(file.name)
    setUploadProgress({
      field: type,
      fileName: file.name,
      progress: 0,
    })

    const result = await uploadFile(file, signedUrl, (progress) => {
      setUploadProgress((prevProgress) => ({
        ...prevProgress,
        progress: progress,
      }))
    })

    if (result.status === 'successful') {
      setUploadProgress((prevProgress) => ({
        ...prevProgress,
        progress: 100,
      }))

      const data: Record<string, string> = {}

      if (type === 'icon') {
        data.logo = fileUrl + '/' + file.name
      } else if (type === 'logo') {
        data.logoWithBrandName = fileUrl + '/' + file.name
      } else {
        data.coverImage = fileUrl + '/' + file.name
      }

      const res = organizationId
        ? await updateOrganization(organizationId, data)
        : { status: 'failed' }

      if (res?.status === 'successful') {
        onUpdate?.(res.data)
        setUploadProgress({
          field: '',
          fileName: '',
          progress: 0,
        })
      }
    }

    setIsUploading(false)
  }

  const onDelete = async (url: string, type: string): Promise<void> => {
    const fileName = url.split('/')[3]
    const result = await deleteFile(fileName)
    const data: Record<string, string | null> = {}
    if (type === 'icon') {
      data.logo = null
    } else if (type === 'logo') {
      data.logoWithBrandName = null
    } else {
      data.coverImage = null
    }

    if (result.status === 'successful') {
      const res = organizationId
        ? await updateOrganization(organizationId, data)
        : { status: 'failed' }

      if (res && res.status === 'successful') onUpdate?.(res.data)
    }
  }

  return (
    <Box flex={1}>
      <h2 className="text-lg font-semibold ml-4">Branding</h2>
      <Card>
        <Grid container rowSpacing={2} columnSpacing={2}>
          <Grid item xs={12} sm={6}>
            <Label htmlFor="icon" text="Icon" />
            {icon && (
              <>
                <Link
                  href={icon}
                  underline="hover"
                  target="_blank"
                  rel="noopener"
                >
                  {icon.split('/')[3]}
                </Link>
                <IconButton
                  aria-label="delete"
                  size="small"
                  onClick={() => onDelete(icon, 'icon')}
                  className="ml-4 hover:text-red-600"
                >
                  <DeleteIcon
                    fontSize="inherit"
                    className="text-gray-600 hover:text-red-600"
                  />
                </IconButton>
              </>
            )}
            {!icon && (
              <>
                <InputFileUpload
                  id="icon"
                  name="icon"
                  onChange={handleFileChange}
                  buttonText={isUploading ? 'Uploading' : 'Upload File'}
                />
                {uploadProgress.field === 'icon' && (
                  <Typography
                    sx={{
                      fontSize: '0.8rem',
                      color: 'accents.secondaryAccent',
                    }}
                  >
                    {uploadProgress.fileName} {uploadProgress.progress}
                  </Typography>
                )}
                {error && error.type === 'icon' && (
                  <p className="text-xs text-red-500 block mt-1">
                    {error.message}
                  </p>
                )}
              </>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <Label text="Logo" />
            {logo && (
              <>
                <Link
                  href={logo}
                  underline="hover"
                  target="_blank"
                  rel="noopener"
                >
                  {logo.split('/')[3]}
                </Link>
                <IconButton
                  aria-label="delete"
                  size="small"
                  onClick={() => onDelete(logo, 'logo')}
                  className="ml-4 hover:text-red-600"
                >
                  <DeleteIcon
                    fontSize="inherit"
                    className="text-gray-600 hover:text-red-600"
                  />
                </IconButton>
              </>
            )}
            {!logo && (
              <>
                <InputFileUpload
                  name="logo"
                  onChange={handleFileChange}
                  buttonText={isUploading ? 'Uploading' : 'Upload File'}
                />
                {uploadProgress.field === 'logo' && (
                  <Typography
                    sx={{
                      fontSize: '0.8rem',
                      color: 'accents.secondaryAccent',
                    }}
                  >
                    {uploadProgress.fileName} {uploadProgress.progress}
                  </Typography>
                )}
                {error && error.type === 'logo' && (
                  <p className="text-xs text-red-500 block mt-1">
                    {error.message}
                  </p>
                )}
              </>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <Label text="Cover Image" />
            {coverImage && (
              <>
                <Link
                  href={coverImage}
                  underline="hover"
                  target="_blank"
                  rel="noopener"
                >
                  {coverImage.split('/')[3]}
                </Link>
                <IconButton
                  aria-label="delete"
                  size="small"
                  onClick={() => onDelete(coverImage, 'cover')}
                  className="ml-4 hover:text-red-600"
                >
                  <DeleteIcon
                    fontSize="inherit"
                    className="text-gray-600 hover:text-red-600"
                  />
                </IconButton>
              </>
            )}
            {!coverImage && (
              <>
                <InputFileUpload
                  name="coverImage"
                  onChange={handleFileChange}
                  buttonText={isUploading ? 'Uploading' : 'Upload File'}
                />
                {uploadProgress.field === 'cover' && (
                  <Typography
                    sx={{
                      fontSize: '0.8rem',
                      color: 'accents.secondaryAccent',
                    }}
                  >
                    {uploadProgress.fileName} {uploadProgress.progress}
                  </Typography>
                )}
                {error && error.type === 'coverImage' && (
                  <p className="text-xs text-red-500 block mt-1">
                    {error.message}
                  </p>
                )}
              </>
            )}
          </Grid>
        </Grid>
      </Card>
    </Box>
  )
}

export default Branding
