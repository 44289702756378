import { useEffect, useRef, useState } from 'react'

import {
  GridFilterModel,
  GridPaginationModel,
  GridSortModel,
} from '@mui/x-data-grid'

import { DataGridTable } from 'pages/dashboard/components/StyledDataGrid'
import { getCustomers } from 'services/customers'
import { Customer } from 'services/customers/index.type'
import { getOperator } from 'utils/helper'

import { columns } from './Columns'
import { CustomToolbar } from './CustomToolbar'

interface CustomersListProps {
  orgId: number
}

interface FilterModelType {
  filterField: string
  filterValue: string
  filterOperator: string
  filterLogicOperator: 'AND' | 'OR' | ''
}

const CustomersList: React.FC<CustomersListProps> = ({
  orgId,
}): JSX.Element => {
  const [list, setList] = useState<Customer[]>([])
  const [rowCount, setRowCount] = useState<number>(0)
  const cursor = useRef<{
    cursorPoint: number
    direction: 'forward' | 'backward' | ''
  }>({ cursorPoint: 0, direction: '' })
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 25,
  })
  const [sortingModel, setSortingModel] = useState<{
    sortField: string
    sortDirection: 'asc' | 'desc' | ''
  }>({
    sortField: '',
    sortDirection: '',
  })

  const [filterModel, setFilterModel] = useState<FilterModelType>({
    filterField: '',
    filterValue: '',
    filterOperator: '',
    filterLogicOperator: '',
  })

  //Implement cursor based pagination.
  //to move forward, use last item's id as cursorPoint and direction 'forward'
  //to move backword, use first item's id as cursorPoint and direction 'backward' as query.
  const handlePaginationModelChange = (
    newPaginationModel: GridPaginationModel
  ): void => {
    // Checking previous page no. > current page no. if true move backward
    if (paginationModel.page > newPaginationModel.page) {
      cursor.current.cursorPoint = list[0].id
      cursor.current.direction = 'backward'
      setPaginationModel(newPaginationModel)
    } else if (paginationModel.page < newPaginationModel.page) {
      cursor.current.cursorPoint = list[list.length - 1].id
      cursor.current.direction = ''
      setPaginationModel(newPaginationModel)
    } else if (paginationModel.page === newPaginationModel.page) {
      cursor.current.cursorPoint = 0
      cursor.current.direction = ''
      setPaginationModel({ page: 0, pageSize: newPaginationModel.pageSize })
    }
  }

  useEffect(() => {
    async function initialFetch(): Promise<void> {
      const dir = cursor.current.direction
      const queryOptions = {
        orgId,
        cursor: cursor.current.cursorPoint,
        pageSize: paginationModel.pageSize,
        direction: dir,
        sortField: sortingModel.sortField,
        sortDirection: sortingModel.sortDirection,
        filterField: filterModel.filterField,
        filterValue: filterModel.filterValue,
        filterOperator: filterModel.filterOperator,
        filterLogicOperator: filterModel.filterLogicOperator,
      }
      const result = await getCustomers(queryOptions)
      if (result) {
        setRowCount(result.total)
        setList(result.customers)
        if (result.customers.length > 0) {
          cursor.current.cursorPoint =
            result.customers[result.customers.length - 1].id
        }
      }
    }
    initialFetch()
  }, [orgId, paginationModel, sortingModel, filterModel])

  // Sorting
  // Query parameters structure: /?sortField=''&sortDirection=''
  // Use the column's fieldName as the value for sortField
  const handleSortModelChange = (sortModel: GridSortModel): void => {
    if (sortModel.length === 0) {
      setSortingModel({ sortField: '', sortDirection: '' })
    } else {
      if (sortModel[0].field === 'fullName')
        sortModel[0].field = 'firstName,lastName'
      setSortingModel({
        sortField: sortModel[0].field,
        sortDirection: sortModel[0].sort ?? '',
      })
    }
    setPaginationModel({
      page: 0,
      pageSize: paginationModel.pageSize,
    })
    cursor.current.cursorPoint = 0
    cursor.current.direction = ''
  }

  // filter
  // newFilterModel provides necessary column values  for filtering
  const onFilterChange = (newFilterModel: GridFilterModel): void => {
    if (newFilterModel.items.length === 0) return

    if (
      newFilterModel.items.length > 0 &&
      newFilterModel.items[0].value === undefined
    ) {
      setFilterModel({
        filterField: '',
        filterValue: '',
        filterOperator: '',
        filterLogicOperator: '',
      })
    } else {
      const operator = getOperator(newFilterModel.items[0].operator)
      if (newFilterModel.items[0].field === 'createdAt') {
        const inputDate = new Date(newFilterModel.items[0].value)
        newFilterModel.items[0].value = inputDate.toISOString()
      }
      setFilterModel({
        filterField: newFilterModel.items[0].field,
        filterValue: newFilterModel.items[0].value,
        filterOperator: operator,
        filterLogicOperator: (newFilterModel.logicOperator?.toUpperCase() ??
          '') as 'AND' | 'OR' | '',
      })
    }
    setPaginationModel({
      page: 0,
      pageSize: paginationModel.pageSize,
    })
    cursor.current.cursorPoint = 0
    cursor.current.direction = ''
  }

  const rows = list?.map((rowData: Customer) => {
    const amountSpent = rowData.order.reduce(
      (acc, item) => acc + item.totalPrice,
      0
    )
    return {
      id: rowData.id,
      fullName: `${rowData.firstName} ${rowData.lastName}`,
      address: rowData.defaultAddress
        ? `${rowData.defaultAddress.city},${rowData.defaultAddress.state},${rowData.defaultAddress.country}`
        : 'Not Available',
      createdAt: rowData.createdAt,
      phoneNumber: rowData.phoneNumber,
      email: rowData.email,
      amountSpent,
    }
  })

  return (
    <div className="mx-5">
      <DataGridTable
        rows={rows}
        columns={columns}
        initialState={{
          pagination: { paginationModel: { pageSize: 5 } },
        }}
        pageSizeOptions={[25, 50, 100]}
        slots={{
          toolbar: CustomToolbar,
        }}
        checkboxSelection
        disableRowSelectionOnClick
        disableColumnMenu
        pagination
        paginationMode="server"
        rowCount={rowCount}
        onPaginationModelChange={handlePaginationModelChange}
        paginationModel={paginationModel}
        sortingMode="server"
        onSortModelChange={handleSortModelChange}
        filterMode="server"
        onFilterModelChange={onFilterChange}
      />
    </div>
  )
}

export default CustomersList
