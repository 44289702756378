import { useState } from 'react'

import { CircularProgress, Button, Dialog } from '@mui/material'
import { useTheme } from '@mui/material/styles'

import { getPresignedUrl, uploadFile } from 'services/files'
import { validateProductImage } from 'utils/validation'

import MultiImageCropper from './FileCropDialog'
import FileUploadList from './FileUploadList'
import FileUploadZone from './FileUploadZone'

interface FileUploadProps {
  onClose?: () => void
  onSuccess: (file: string) => void
}
const FileUpload = ({ onClose, onSuccess }: FileUploadProps): JSX.Element => {
  const theme = useTheme()
  const [files, setFiles] = useState<File[]>([])
  const [uploadProgress, setUploadProgress] = useState<Record<string, number>>(
    {}
  )
  const [uploading, setUploading] = useState<boolean>(false)
  const [error, setError] = useState({ valid: true, message: '' })
  const [showCropper, setShowCropper] = useState<boolean>(false)
  const [croppedFiles, setCroppedFiles] = useState<File[]>([])

  const addFiles = async (accfiles: File[]): Promise<void> => {
    setError({ valid: true, message: '' })
    // Removing files that already added
    const filterFiles = accfiles.filter(
      (file) => !files.some((existingFile) => existingFile.name === file.name)
    )

    const fileCropList: File[] = []
    for (let i = filterFiles.length - 1; i >= 0; i--) {
      const file = filterFiles[i]
      const validationResult = await validateProductImage(file)
      if (!validationResult.valid) {
        // Remove invalid file
        if (validationResult?.errorType === 'AspectRatio') {
          fileCropList.push(file)
        } else {
          setError(validationResult)
        }
        filterFiles.splice(i, 1)
      }
    }

    setFiles(filterFiles)
    setCroppedFiles(fileCropList)
    if (fileCropList.length > 0) setShowCropper(true)
  }

  const deleteFile = (file: File): void => {
    setFiles((prev) => prev.filter((f) => f !== file))
  }

  const handleCropComplete = (croppedFiles: File[]): void => {
    setCroppedFiles([])
    setFiles((prev) => [...prev, ...croppedFiles])
    setShowCropper(false)
  }

  const fileSubmitHandler = async (): Promise<void> => {
    if (files.length === 0) return
    setUploading(true)
    try {
      for (const file of files) {
        if (uploadProgress[file.name] === 100) continue

        //Get presigned url
        const signedUrl = await getPresignedUrl(file.name)
        setUploadProgress((prevProgress) => ({
          ...prevProgress,
          [file.name]: 0,
        }))

        // upload file to presigned url
        const result = await uploadFile(file, signedUrl, (progress) => {
          setUploadProgress((prevProgress) => ({
            ...prevProgress,
            [file.name]: progress,
          }))
        })

        if (result.status === 'successful') {
          onSuccess(file.name)
          setUploadProgress((prevProgress) => ({
            ...prevProgress,
            [file.name]: 100,
          }))
        }
      }
      onClose?.()
    } catch (error) {
      console.error('Error uploading files:', error)
    } finally {
      setUploading(false)
      setError({ valid: true, message: '' })
    }
  }

  const handleCropCancel = (): void => {
    setShowCropper(false)
    setCroppedFiles([])
  }

  return (
    <div className="border border-solid border-black p-6 rounded-lg bg-gray-100">
      <h5 className="text-lg font-semibold">Upload Files</h5>
      <p className="text-sm text-gray-500 mb-2">
        Select Files from your device or drag and drop files here
      </p>
      <FileUploadZone onAddFiles={addFiles} />
      {!error.valid && (
        <p className="text-xs text-red-500 mb-2 mt-1">{error.message}</p>
      )}
      {files.length > 0 && (
        <>
          <FileUploadList
            files={files}
            onDelete={deleteFile}
            progress={uploadProgress}
          />
          <div className="flex justify-between mt-4">
            {onClose && (
              <Button
                variant="outlined"
                onClick={onClose}
                sx={{ color: theme.palette.primary.main }}
                disabled={uploading}
                className="border border-solid border-blue-500 text-blue-500 px-4 py-2 rounded-md hover:bg-blue-500 hover:text-white"
              >
                Cancel
              </Button>
            )}
            <Button
              variant="contained"
              onClick={fileSubmitHandler}
              disabled={uploading}
              className={`${
                uploading
                  ? 'bg-gray-400 cursor-not-allowed'
                  : 'bg-blue-500 hover:bg-blue-600'
              } text-white px-4 py-2 rounded-md`}
            >
              {uploading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                'Submit'
              )}
            </Button>
          </div>
        </>
      )}

      {showCropper && (
        <Dialog open={showCropper} maxWidth="md" fullWidth>
          <MultiImageCropper
            files={croppedFiles}
            onCropComplete={handleCropComplete}
            onClose={handleCropCancel}
          />
        </Dialog>
      )}
    </div>
  )
}

export default FileUpload
