import { User } from 'types/auth'
import { getToken } from 'utils/authCookies'

import {
  LoginProps,
  ConfirmRegistrationProps,
  RegisterProps,
  ResendCodeProps,
  ForgotPasswordProps,
  ConfirmForgotPasswordProps,
  RequestAccessParams,
  ChangePasswordParams,
} from './index.types'

const userBasePath = `${process.env.REACT_APP_API_BASE_PATH}/user`

const login = async ({ email, password }: LoginProps) => {
  try {
    const response = await fetch(`${userBasePath}/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, password }),
    })

    const { data, message } = await response.json()

    if (response.ok) {
      return { status: 'successful', data }
    }

    throw new Error(message || 'Login failed')
  } catch (error) {
    console.error('Error logging in:', error)
    throw error
  }
}

const register = async ({
  firstName,
  lastName,
  email,
  password,
}: RegisterProps) => {
  try {
    const response = await fetch(`${userBasePath}/register`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ firstName, lastName, email, password }),
    })

    const { message } = await response.json()
    if (response.ok) return { status: 'successful' }
    return { status: 'failed', error: message }
  } catch (error) {
    console.error('Error registering: ', error)
    throw error
  }
}

const confirmRegistration = async ({
  email,
  password,
  confirmationCode,
}: ConfirmRegistrationProps) => {
  try {
    const response = await fetch(`${userBasePath}/confirm-registration`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, password, confirmationCode }),
    })

    const { data, message } = await response.json()
    if (response.ok) return { status: 'successful', data }

    throw new Error(message || 'Sign up failed')
  } catch (error) {
    console.error('Error confirming registration:', error)
    throw error
  }
}

const resendCode = async ({ email }: ResendCodeProps) => {
  try {
    const response = await fetch(`${userBasePath}/resend-confirmation`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    })

    const { data } = await response.json()
    return data
  } catch (error) {
    console.error('Error resending code:', error)
    throw error
  }
}

const forgotPassword = async ({ email }: ForgotPasswordProps) => {
  try {
    const response = await fetch(`${userBasePath}/forgot-password`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    })

    if (response.ok) {
      return { status: 'successful' }
    } else {
      const errorData = await response.json()
      throw new Error(
        errorData.message || 'Failed to send forgot password request'
      )
    }
  } catch (error) {
    console.error('Error sending forgot password request:', error)
    throw error
  }
}

const confirmForgotPassword = async ({
  email,
  confirmationCode,
  password,
}: ConfirmForgotPasswordProps) => {
  try {
    const response = await fetch(`${userBasePath}/confirm-forgot-password`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, confirmationCode, password }),
    })

    if (response.ok) {
      return { status: 'successful' }
    } else {
      const errorData = await response.json()
      throw new Error(
        errorData.message || 'Failed to confirm forgot password request'
      )
    }
  } catch (error) {
    console.error('Error confirming forgot password request:', error)
    throw error
  }
}

const logout = async (token: string) => {
  try {
    const response = await fetch(`${userBasePath}/sign-out`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({}),
    })
    if (response.ok) {
      return { status: 'successful' }
    } else {
      const errorData = await response.json()
      throw new Error(errorData.message || 'Failed to sign out')
    }
  } catch (error) {
    console.error('Error signing out', error)
    throw error
  }
}

const sendRefreshToken = async (token: string) => {
  try {
    const response = await fetch(`${userBasePath}/refresh-token`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ token }),
    })
    if (response.ok) {
      const { data } = await response.json()
      return data
    }
  } catch (error) {
    console.error('Error refresh token', error)
    throw error
  }
}

const getUser = async (): Promise<User | undefined> => {
  const token = await getToken()

  try {
    const res = await fetch(`${userBasePath}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })

    const { data } = await res.json()
    if (data) return data
  } catch (error) {
    console.error(error)
  }
}

const earlyRequestAccess = async (values: RequestAccessParams) => {
  try {
    const res = await fetch(
      `${process.env.REACT_APP_API_BASE_PATH}/enquiries/request-access`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(values),
      }
    )

    if (res.ok) return { status: 'success' }
  } catch (error) {
    console.error(error)
  }
}

const changePassword = async (values: ChangePasswordParams) => {
  const token = await getToken()
  const accessToken = await getToken('AccessToken')

  try {
    const response = await fetch(`${userBasePath}/change-password`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: ` Bearer ${token}`,
      },
      body: JSON.stringify({ ...values, accessToken }),
    })
    if (response.ok) {
      return { status: 'success' }
    }
  } catch (error) {
    console.error(error)
  }
}

export {
  register,
  login,
  confirmRegistration,
  forgotPassword,
  resendCode,
  confirmForgotPassword,
  logout,
  sendRefreshToken,
  getUser,
  earlyRequestAccess,
  changePassword,
}
