import { FC } from 'react'

import { Button } from '@mui/material'

interface EditButtonProps {
  buttonText: string
  onClick?: () => void
}

const EditButton: FC<EditButtonProps> = ({
  buttonText,
  onClick,
}): JSX.Element => {
  return (
    <Button
      disableElevation
      sx={{
        p: '1px',
        backgroundColor: 'accents.darkGrayAccent',
        color: 'text.primary',
        fontSize: '0.8rem',
        fontWeight: 700,
        textTransform: 'none',
        ':hover': {
          backgroundColor: '#bdbdbd',
        },
      }}
      onClick={onClick}
    >
      {buttonText}
    </Button>
  )
}
export default EditButton
