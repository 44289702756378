import React from 'react'

import { zodResolver } from '@hookform/resolvers/zod'
import { TextField, Button, Grid, Typography, Box } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useForm, Controller } from 'react-hook-form'
import { z } from 'zod'

import { useSnackbar } from 'hooks/useSnackbar'
import { earlyRequestAccess } from 'services/auth'

const FormContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
  marginBottom: theme.spacing(15),
  borderRadius: '8px',
  backgroundColor: theme.palette.secondary.light,
  boxShadow: theme.shadows[2],
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(8),
  },
}))

const formDataSchema = z.object({
  name: z.string().min(1, { message: 'Name is required' }),
  businessName: z
    .string()
    .min(2, { message: 'Business name must be at least 2 characters long' })
    .min(1, { message: 'Business name is required' }),
  email: z
    .string()
    .email({ message: 'Email must be a valid email address' })
    .min(1, { message: 'Email is required' }),
  instagramPage: z.string().optional(),
})

type FormData = {
  name: string
  businessName: string
  email: string
  instagramPage?: string
}

const ContactUsForm: React.FC = () => {
  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
    reset,
  } = useForm<FormData>({
    resolver: zodResolver(formDataSchema),
  })
  const { showSnackbar } = useSnackbar()

  const onSubmit = async (data: FormData): Promise<void> => {
    const { name, businessName, email, instagramPage } = data
    const res = await earlyRequestAccess({
      name,
      businessName,
      email,
      instagramPage: instagramPage === '' ? undefined : instagramPage,
    })

    if (res && res.status === 'success') {
      showSnackbar('Your access request submitted successfully', 'success')
      reset()
    }
  }

  return (
    <Box id="request-early-access">
      <Typography
        variant="h4"
        align="center"
        gutterBottom
        sx={{
          mb: { xs: 4, sm: 6 },
          fontWeight: 'bold',
          color: 'text.primary',
        }}
      >
        Request Early Access
      </Typography>
      <FormContainer>
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Controller
                name="name"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    className="bg-white"
                    {...field}
                    label="Name"
                    variant="outlined"
                    fullWidth
                    required
                    error={Boolean(errors.name)}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="businessName"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    className="bg-white"
                    {...field}
                    label="Business Name"
                    variant="outlined"
                    fullWidth
                    required
                    error={Boolean(errors.businessName)}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="email"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    className="bg-white"
                    {...field}
                    label="Email"
                    variant="outlined"
                    fullWidth
                    required
                    type="email"
                    error={Boolean(errors.email)}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="instagramPage"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    className="bg-white"
                    {...field}
                    label="Instagram Page (if applicable)"
                    variant="outlined"
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} display="flex" justifyContent="center">
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={isSubmitting}
              >
                {!isSubmitting ? 'Send' : 'Sending'}
              </Button>
            </Grid>
          </Grid>
        </form>
      </FormContainer>
    </Box>
  )
}

export default ContactUsForm
