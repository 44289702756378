import React from 'react'

import {
  Store,
  ShoppingCart,
  Payment,
  BarChart,
  LocalShipping,
  Security,
} from '@mui/icons-material'
import {
  Grid,
  Typography,
  Box,
  Container,
  Card,
  CardContent,
  IconButton,
} from '@mui/material'

interface FeatureProps {
  icon: React.ReactElement
  title: string
  description: string
}

const featuresData: FeatureProps[] = [
  {
    icon: <Store fontSize="large" />,
    title: 'Easy Store Setup',
    description:
      'Quickly set up your online store with a user-friendly interface that requires no technical expertise.',
  },
  {
    icon: <ShoppingCart fontSize="large" />,
    title: 'Seamless Instagram Integration',
    description:
      'Directly link your Instagram profile to your Haulistic store, turning your social media posts into shoppable products.',
  },
  {
    icon: <Payment fontSize="large" />,
    title: 'Secure Payment Processing',
    description:
      'Integrate with multiple payment gateways to offer secure and reliable payment options to your customers.',
  },
  {
    icon: <LocalShipping fontSize="large" />,
    title: 'Shipping and Delivery Integration',
    description:
      'Manage shipping and delivery seamlessly, with real-time tracking and updates.',
  },
  {
    icon: <BarChart fontSize="large" />,
    title: 'Advanced Analytics',
    description:
      'Gain insights into your sales performance, customer behavior, and marketing effectiveness with detailed analytics.',
  },
  {
    icon: <Security fontSize="large" />,
    title: 'Scalable Solutions',
    description:
      'Whether you’re starting small or scaling up, Haulistic grows with your business.',
  },
]

const FeatureCard: React.FC<FeatureProps> = ({ icon, title, description }) => {
  return (
    <div data-aos="flip-left">
      <Card
        sx={{
          minHeight: '250px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          transition: 'transform 0.3s ease',
          '&:hover': {
            transform: 'translateY(-10px)',
            boxShadow: 3,
          },
        }}
      >
        <CardContent>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              mb: 2,
            }}
          >
            <IconButton
              sx={{
                backgroundColor: 'primary.main',
                color: 'white',
                '&:hover': {
                  backgroundColor: 'primary.dark',
                },
              }}
              size="large"
            >
              {icon}
            </IconButton>
          </Box>
          <Typography variant="h6" gutterBottom>
            {title}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {description}
          </Typography>
        </CardContent>
      </Card>
    </div>
  )
}

const FeaturesSection: React.FC = () => {
  return (
    <Box
      sx={{
        py: { xs: 0, md: 8 },
        mb: { xs: 8, md: 6 },
      }}
    >
      <Container maxWidth="lg">
        <Typography
          variant="h4"
          align="center"
          gutterBottom
          sx={{
            mb: { xs: 4, sm: 6 },
            fontWeight: 'bold',
            color: 'text.primary',
          }}
        >
          Key Features
        </Typography>
        <Grid container spacing={{ xs: 2, sm: 4 }} justifyContent="center">
          {featuresData.map((feature, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <FeatureCard
                icon={feature.icon}
                title={feature.title}
                description={feature.description}
              />
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  )
}

export default FeaturesSection
