import React from 'react'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Box, Typography } from '@mui/material'

interface AccordionHeaderProps {
  productName: string
  lastUpdated: string
  lastUpdatedBy: string
  expanded: boolean
}

const StockAccordionHeader: React.FC<AccordionHeaderProps> = ({
  productName,
  lastUpdated,
  lastUpdatedBy,
  expanded,
}) => (
  <>
    <ExpandMoreIcon
      sx={{
        transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
        transition: 'transform 0.3s ease-in-out',
        marginRight: 2,
      }}
    />
    <Box className="flex-1 ">
      <Typography variant="body2" className=" text-gray-500">
        Product
      </Typography>
      <Typography className="border-b-2 border-gray-500 inline-block ">
        {productName}
      </Typography>
    </Box>
    <Box sx={{ flex: 1, textAlign: 'left' }}>
      <Typography variant="body2" className=" text-gray-500">
        Last Updated At
      </Typography>
      <Typography>{lastUpdated}</Typography>
    </Box>
    <Box sx={{ flex: 0.5, textAlign: 'left', marginLeft: 2 }}>
      <Typography variant="body2" className=" text-gray-500">
        Last Updated By
      </Typography>
      <Typography>{lastUpdatedBy}</Typography>
    </Box>
  </>
)

export default StockAccordionHeader
