import React from 'react'

import { Paper } from '@mui/material'

const Card: React.FC<React.PropsWithChildren> = ({ children }) => {
  const style = {
    backgroundColor: '#F4F7F6',
    boxShadow: '0 0 8px 2px rgba(0, 0, 0, 0.1)',
    padding: '20px',
    margin: '20px',
    borderRadius: '5px',
  }

  return <div style={style}>{children}</div>
}

export const SubCard: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <Paper
      component={'div'}
      className=" my-2 p-5 shadow"
      style={{
        boxShadow: '0 0 8px 2px rgba(0, 0, 0, 0.1)',
      }}
    >
      {children}
    </Paper>
  )
}

export default Card
