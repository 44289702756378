import DeleteIcon from '@mui/icons-material/Delete'
import {
  Box,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  Typography,
} from '@mui/material'

type FileUploadListProps = {
  files: File[]
  onDelete: (file: File) => void
  progress: Record<string, number>
}

function LinearProgressWithLabel(props: { value: number }): JSX.Element {
  return (
    <Box className="flex items-center">
      <Box className="w-full mr-1">
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box className="min-w-35">
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  )
}

function formatFileSize(size: number): string {
  if (size < 1000000) {
    return `${(size / 1000).toFixed(2)} KB`
  } else {
    return `${(size / 1000000).toFixed(2)} MB`
  }
}

const FileUploadList = ({
  files,
  onDelete,
  progress,
}: FileUploadListProps): JSX.Element => {
  return (
    <List>
      {files.map((file) => (
        <ListItem
          key={file.name}
          className="bg-gray-200 rounded-md flex flex-col mb-2"
        >
          <Typography variant="body2" className="py-2 px-4">
            {file.name} ({formatFileSize(file.size)})
            {progress[file.name] === undefined && (
              <IconButton
                aria-label="delete"
                size="small"
                onClick={() => onDelete(file)}
                className="ml-2 hover:text-red-600"
              >
                <DeleteIcon
                  fontSize="inherit"
                  className="text-gray-600 hover:text-red-600"
                />
              </IconButton>
            )}
          </Typography>
          {progress[file.name] !== undefined && progress[file.name] > 0 && (
            <Box className="w-full">
              <LinearProgressWithLabel value={progress[file.name]} />
            </Box>
          )}
        </ListItem>
      ))}
    </List>
  )
}

export default FileUploadList
