import { Box, Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import TabTitle from 'pages/dashboard/components/TabTitle'

import CustomerEdit from '../components/CustomerEdit'

const AddCustomerPage = (): JSX.Element => {
  const navigate = useNavigate()
  const handleBackNavigation = (): void => {
    navigate(-1)
  }
  return (
    <>
      <Box
        display="flex"
        alignItems={'center'}
        justifyContent={'space-between'}
        marginX={2}
        paddingRight={3}
      >
        <TabTitle title="New Customer" />
        <Button variant="outlined" size="small" onClick={handleBackNavigation}>
          Cancel
        </Button>
      </Box>
      <CustomerEdit />
    </>
  )
}

export default AddCustomerPage
