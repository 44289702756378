import { FC } from 'react'

import Typography from '@mui/material/Typography'

interface TabTitleProps {
  title: string
}

const TabTitle: FC<TabTitleProps> = ({ title }) => {
  return (
    <Typography
      sx={{
        fontWeight: '700',
        fontSize: '1.2rem',
      }}
    >
      {title}
    </Typography>
  )
}

export default TabTitle
