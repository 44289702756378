import { ChangeEvent, FC, FocusEvent, useEffect, useState } from 'react'

import { Box, Grid, InputAdornment, Typography } from '@mui/material'

import { SubCard } from 'components/card'
import { useSnackbar } from 'hooks/useSnackbar'
import CustomTextField from 'pages/dashboard/components/CustomTextField'
import EditButton from 'pages/dashboard/components/EditButton'
import PhoneNumberInput from 'pages/dashboard/components/PhoneNumberInput'
import { updateCustomer } from 'services/customers'
import { Customer } from 'services/customers/index.type'
import { getRestrictedCountriesList } from 'utils/countries'
import {
  getCountryCodeAndPhoneNumber,
  validatePhoneNumber,
} from 'utils/phoneNumber'

interface ContactInformationProps {
  customerId: number
  phoneNumber?: string
  email: string
  instagramId?: string
  setData: (data: Customer) => void
}

const ContactInformation: FC<ContactInformationProps> = ({
  customerId,
  phoneNumber,
  email,
  instagramId,
  setData,
}) => {
  const { showSnackbar } = useSnackbar()
  const [edit, setEdit] = useState(false)
  const [error, setError] = useState({
    countryCode: '',
    phoneNo: '',
    email: '',
  })

  const [values, setValues] = useState({
    countryCode: '',
    phoneNo: '',
    email: '',
    instagramId: '',
  })
  const countryList = getRestrictedCountriesList()

  useEffect(() => {
    const phoneNoData = phoneNumber
      ? getCountryCodeAndPhoneNumber(phoneNumber)
      : undefined

    const instaUserName = instagramId ? instagramId.split('/')[3] : undefined

    setValues({
      countryCode: phoneNoData ? `+${phoneNoData.countryCode}` : '',
      phoneNo: phoneNoData ? `${phoneNoData.phoneNumber}` : '',
      email: email,
      instagramId: instaUserName ?? '',
    })
  }, [phoneNumber, email, instagramId])

  const handlePhoneNumberChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target
    setValues((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  const handlePhoneNumberBlur = (e: FocusEvent<HTMLInputElement>): void => {
    const { name, value } = e.target
    const isCountryCode = name === 'countryCode'

    setError((prev) => ({
      ...prev,
      [name]:
        value === ''
          ? `*${isCountryCode ? 'Country code' : 'Phone number'} is required.`
          : '',
    }))
  }

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setValues((prev) => ({ ...prev, email: e.target.value }))
  }

  const handleEmailBlur = (e: FocusEvent<HTMLInputElement>): void => {
    setError((prev) => ({
      ...prev,
      email: e.target.value === '' ? '*Email is required.' : '',
    }))
  }

  const handleInstagramIdChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setValues((prev) => ({ ...prev, instagramId: e.target.value }))
  }

  const handleEdit = async (): Promise<void> => {
    if (edit) {
      if (
        values.email === '' ||
        values.phoneNo === '' ||
        values.countryCode === ''
      ) {
        setError({
          countryCode:
            values.countryCode === '' ? '*Country code is required.' : '',
          phoneNo: values.phoneNo === '' ? '*Phone number is required.' : '',
          email: values.email === '' ? '*Email is required.' : '',
        })
        return
      }

      const isValid = validatePhoneNumber(values.countryCode, values.phoneNo)

      if (!isValid) {
        setError((prev) => ({
          ...prev,
          phoneNo: '*Phone number is incorrect.',
        }))
        return
      }

      const data = {
        phoneNumber: `${values.countryCode}${values.phoneNo}`,
        email: values.email,
        instagramId:
          values.instagramId !== ''
            ? `https://www.instagram.com/${values.instagramId}`
            : '',
      }

      if (
        data.email === email &&
        data.instagramId === instagramId &&
        data.phoneNumber === phoneNumber
      ) {
        setEdit(false)
        return
      }

      const result = await updateCustomer(customerId, data)
      if (result?.status === 'successful' && result.data) {
        showSnackbar('Contact information updated.', 'success')
        setData(result.data)
      } else if (result?.status === 'failed') {
        result.message
          ? showSnackbar(result.message, 'error')
          : showSnackbar('Please try again later.', 'error')
      }
    }
    setEdit((prev) => !prev)
  }

  return (
    <Box className="mb-4">
      <Box className=" flex gap-3 items-center">
        <Typography sx={{ fontWeight: '700' }}>Contact Information</Typography>
        <EditButton buttonText={edit ? 'Save' : 'Edit'} onClick={handleEdit} />
      </Box>
      <SubCard>
        <Grid container rowSpacing={2} columnSpacing={2}>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                fontSize: '0.8rem',
                color: 'text.secondary',
                fontWeight: '600',
              }}
            >
              Phone Number
            </Typography>
            {edit ? (
              <PhoneNumberInput
                countryCodeName="countryCode"
                phoneNumberName="phoneNo"
                countriesList={countryList}
                value={{
                  countryCode: values.countryCode,
                  phoneNumber: values.phoneNo,
                }}
                onChange={handlePhoneNumberChange}
                onBlur={handlePhoneNumberBlur}
                error={{
                  countryCode: error.countryCode,
                  phoneNumber: error.phoneNo,
                }}
                helperText={{
                  countryCode: error.countryCode,
                  phoneNumber: error.phoneNo,
                }}
              />
            ) : (
              <Typography sx={{ fontWeight: '700' }}>{phoneNumber}</Typography>
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                fontSize: '0.8rem',
                color: 'text.secondary',
                fontWeight: '600',
              }}
            >
              Email
            </Typography>
            {edit ? (
              <CustomTextField
                name="email"
                type="email"
                value={values.email}
                onChange={handleEmailChange}
                onBlur={handleEmailBlur}
                error={Boolean(error.email)}
                helperText={error.email}
              />
            ) : (
              <Typography sx={{ fontWeight: '700' }}>{email}</Typography>
            )}
          </Grid>
          <Grid item xs={12} md={12}>
            <Typography
              sx={{
                fontSize: '0.8rem',
                color: 'text.secondary',
                fontWeight: '600',
              }}
            >
              Instagram Page
            </Typography>
            {edit ? (
              <CustomTextField
                name="instagramId"
                value={values.instagramId}
                onChange={handleInstagramIdChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      https://www.instagram.com/
                    </InputAdornment>
                  ),
                }}
              />
            ) : (
              <Typography sx={{ fontWeight: '700' }}>
                {instagramId && instagramId !== '' ? instagramId : 'N/A'}
              </Typography>
            )}
          </Grid>
        </Grid>
      </SubCard>
    </Box>
  )
}

export default ContactInformation
