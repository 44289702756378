import { Box } from '@mui/material'
import InputAdornment from '@mui/material/InputAdornment'
import TextField from '@mui/material/TextField'
import { useFormikContext } from 'formik'

import Card from 'components/card'
import Label from 'components/forms/Label'

import { ProductDetailFormValues } from '../pages/AddProductPage'

interface ProductSeoProps {
  productId?: number
}

const ProductSeo: React.FC<ProductSeoProps> = () => {
  const formik = useFormikContext<ProductDetailFormValues>()

  return (
    <div className="mb-5">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        className="mr-4"
      >
        <div>
          <h2 className="text-lg font-semibold ml-4">
            Search Engine Optimization (SEO)
          </h2>

          <p className="text-sm text-gray-500 mb-2 ml-4">
            Add a title and description to see how this product might appear in
            a search engine listing like Google
          </p>
        </div>
      </Box>
      <Card>
        <div className="mb-4">
          <Label text="Title" htmlFor="seoTitle" />
          <TextField
            sx={{ marginTop: 0 }}
            fullWidth
            inputProps={{ style: { backgroundColor: 'white' } }}
            type="text"
            id="seoTitle"
            name="seoTitle"
            value={formik.values.seoTitle}
            onChange={formik.handleChange}
            error={formik.touched.seoTitle && Boolean(formik.errors.seoTitle)}
            helperText={formik.touched.seoTitle && formik.errors.seoTitle}
            size="small"
            onBlur={formik.handleBlur}
          />
          {/* Placeholder button for generating title */}
          <button className="mt-2 bg-gray-200 hover:bg-gray-300 text-gray-800 font-semibold py-2 px-4 rounded">
            Generate Title
          </button>
        </div>
        <div className="mb-4">
          <Label text="Description" htmlFor="description" />
          <textarea
            id="seoDescription"
            name="seoDescription"
            className="w-full border border-gray-300 rounded py-2 px-3"
            rows={3}
            value={formik.values.seoDescription}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {/* Placeholder button for generating description */}
          <button className="mt-2 bg-gray-200 hover:bg-gray-300 text-gray-800 font-semibold py-2 px-4 rounded">
            Generate Description
          </button>
        </div>
        <div className="mb-4">
          <Label text="Product Link" htmlFor="productLink" />
          <TextField
            sx={{ marginTop: 0 }}
            fullWidth
            inputProps={{ style: { backgroundColor: 'white' } }}
            size="small"
            type="text"
            id="seoLink"
            name="seoLink"
            value={formik.values.seoLink}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.seoLink && Boolean(formik.errors.seoLink)}
            helperText={formik.touched.seoLink && formik.errors.seoLink}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  https://haulistic.io/
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div>
          <Label text="Preview" />
          <div className="bg-white border border-gray-300 rounded p-4">
            <div className="flex items-center space-x-2 mb-2">
              {/* Placeholder icon for website */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 text-green-500"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clipRule="evenodd"
                />
              </svg>
              <a
                href={`https://haulistic.io/${formik.values.seoLink}`}
                className="text-sm font-semibold text-green-500"
              >
                {`https://haulistic.io/${formik.values.seoLink}`}
              </a>
            </div>
            <a
              href={`https://haulistic.io/${formik.values.seoLink}`}
              className="text-blue-600 hover:underline"
            >
              <span className="text-lg font-semibold">
                {formik.values.seoTitle}
              </span>
            </a>
            <p className="text-gray-600">{formik.values.seoDescription}</p>
          </div>
        </div>
      </Card>
    </div>
  )
}

export default ProductSeo
