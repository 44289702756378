import React, { useCallback, useContext, useEffect, useState } from 'react'

import DeleteIcon from '@mui/icons-material/Delete'
import {
  Box,
  Typography,
  CircularProgress,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Paper,
  Backdrop,
  Snackbar,
} from '@mui/material'
import { format } from 'date-fns'

import { TemplateContext } from 'contexts/template/templateContext'
import { useAuth } from 'hooks/useAuth'
import TabTitle from 'pages/dashboard/components/TabTitle'
import { deleteTemplateById, getAllTemplates } from 'services/emailTemplate'
import { Template } from 'types/template'

import NewEditorBox from './components/NewEditorBox'
import PredefinedTemplateBox from './components/PredefinedTemplateBox'
import { defaultTemplates } from '../../../../../../utils/defaultTemplates'

const EmailBuilder = () => {
  const { organizationId } = useAuth()
  const { templates, setTemplates } = useContext(TemplateContext)
  const [loading, setLoading] = useState(true)
  const [errorLoading, setError] = useState<boolean>(false)
  const [backdropLoading, setBackdropLoading] = useState(false)

  const fetchData = useCallback(async () => {
    try {
      const customTemplates = (await getAllTemplates(
        organizationId as number
      )) as Template[]
      setTemplates([...customTemplates, ...defaultTemplates])
    } catch (error) {
      console.error('Error fetching data:', error)
    } finally {
      setLoading(false)
    }
  }, [setTemplates])

  useEffect(() => {
    if (templates?.length == 0) {
      fetchData()
    }
  }, [fetchData, templates])

  const handleDeleteTemplate = async (id: number) => {
    try {
      await deleteTemplateById(id)
      setTemplates((prevTemplates: Template[]) =>
        prevTemplates.filter((template) => template.id !== id)
      )
    } catch (error) {
      console.error('Error deleting template:', error)
    }
  }

  return (
    <React.Fragment>
      <Box sx={{ width: '100%', padding: 3 }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: 2,
          }}
        >
          <TabTitle title="Email Builder" />
          <NewEditorBox />
        </Box>
        <Box sx={{ marginLeft: '1.1rem' }}>
          <Typography variant="body1" gutterBottom>
            Create professional email templates with our drag-and-drop builder.
            No coding required!
          </Typography>
          {loading && templates?.length === 0 ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '50vh', // Adjust as needed for vertical centering
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <TableContainer component={Paper} sx={{ marginTop: '30px' }}>
              <Table>
                <TableHead sx={{ backgroundColor: '#e0e0e0' }}>
                  <TableRow>
                    <TableCell>Template Name</TableCell>
                    <TableCell align="right">Last Updated At</TableCell>
                    <TableCell align="right">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {templates.map((template) => (
                    <TableRow
                      key={template.id}
                      sx={{ backgroundColor: '#F8F8F8' }}
                    >
                      <PredefinedTemplateBox
                        template={template}
                        loading={backdropLoading}
                        setLoading={setBackdropLoading}
                        setError={setError}
                      />
                      <TableCell align="right">
                        {format(
                          new Date(template.updatedAt),
                          'MMM dd, yyyy HH:mm:ss'
                        )}
                      </TableCell>
                      <TableCell align="right" sx={{ cursor: 'pointer' }}>
                        {!template.isDefault && (
                          <IconButton
                            color="error"
                            onClick={(e) => {
                              e.stopPropagation()
                              handleDeleteTemplate(template.id)
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Box>
        <Backdrop
          sx={{
            color: '#fff',
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={backdropLoading || errorLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        {errorLoading && (
          <Snackbar
            open={errorLoading}
            autoHideDuration={6000}
            onClose={() => setError(false)}
            message={'Something went wrong. Please try again later.'}
          />
        )}
      </Box>
    </React.Fragment>
  )
}

export default EmailBuilder
