import { useCallback } from 'react'

import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import { Box, Button } from '@mui/material'
import { useDropzone } from 'react-dropzone'

type FileUploadZoneProps = {
  onAddFiles: (files: File[]) => void
}

const FileUploadZone = ({ onAddFiles }: FileUploadZoneProps): JSX.Element => {
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      onAddFiles(acceptedFiles)
    },
    [onAddFiles]
  )

  const { getRootProps, getInputProps, open } = useDropzone({
    multiple: true,
    noClick: true,
    noKeyboard: true,
    accept: { 'image/*': [], 'video/*': [] },
    onDrop,
  })

  return (
    <Box
      sx={{
        backgroundColor: '#ffffff',
        paddingY: '20px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        border: '2px dashed #ccc',
        borderRadius: '8px',
      }}
      {...getRootProps()}
    >
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <input {...getInputProps()} />
      <p className="text-sm text-gray-600 mb-2 ml-4">
        Drag and drop files here
      </p>
      <Button
        variant="text"
        color="primary"
        onClick={open}
        startIcon={<CloudUploadIcon />}
      >
        Click here to select
      </Button>
    </Box>
  )
}

export default FileUploadZone
