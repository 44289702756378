import { useEffect, useState } from 'react'

import Link from '@mui/material/Link'

import { forgotPassword } from 'services/auth'

interface ResendCodeProps {
  email: string
}

const ResendOtp: React.FC<ResendCodeProps> = ({ email }) => {
  const [timer, setTimer] = useState<number>(60)
  const [resendLinkVisible, setResendLinkVisible] = useState<boolean>(true)

  const handleResendOTP = async (): Promise<void> => {
    await forgotPassword({ email })
    setTimer(60)
    setResendLinkVisible(false)
  }

  useEffect(() => {
    let interval: NodeJS.Timeout

    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1)
      }, 1000)
    }

    return () => clearInterval(interval)
  }, [timer])
  return (
    <div className="mt-2">
      {timer === 0 ? (
        <Link
          className={`text-sm font-semibold cursor-pointer ${
            resendLinkVisible ? 'text-black hover:underline' : 'text-gray-500'
          }`}
          onClick={handleResendOTP}
        >
          Resend OTP
        </Link>
      ) : (
        <span className="text-sm font-semibold text-gray-500">
          Resend OTP after {timer} seconds
        </span>
      )}
    </div>
  )
}

export default ResendOtp
