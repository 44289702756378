import { createContext, useState, ReactNode } from 'react'

import { saveTemplateContent, updateTemplate } from 'services/emailTemplate'
import { TemplateContextType, Template } from 'types/template'

const defaultContext: TemplateContextType = {
  templates: [],
  setTemplates: () => {},
  saveCurrTemplate: async () => {},
  updateCurrTemplate: async () => {},
}

export const TemplateContext =
  createContext<TemplateContextType>(defaultContext)

export const TemplateProvider = ({ children }: { children: ReactNode }) => {
  const [templates, setTemplates] = useState<Template[]>([])

  const saveCurrTemplate = async (
    content: string,
    templateName: string,
    organizationId: number
  ): Promise<void> => {
    const newTemplate: Template = await saveTemplateContent({
      content,
      templateName,
      orgId: organizationId,
    })
    setTemplates([newTemplate, ...templates])
  }

  const updateCurrTemplate = async (
    id: string,
    templateContent: string,
    templateName: string
  ): Promise<void> => {
    const updatedTemplate: Template = await updateTemplate(
      id,
      templateContent,
      templateName
    ) // Change the HTML Content into s3
    setTemplates((prevTemplates) => {
      // Remove the old version of the updated template
      const filteredTemplates = prevTemplates.filter(
        (t) => t.id !== parseInt(id)
      )
      // Insert the updated template at the beginning
      return [updatedTemplate, ...filteredTemplates]
    })
  }

  return (
    <TemplateContext.Provider
      value={{
        templates,
        setTemplates,
        saveCurrTemplate,
        updateCurrTemplate,
      }}
    >
      {children}
    </TemplateContext.Provider>
  )
}
