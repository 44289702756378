import { PhoneNumberUtil } from 'google-libphonenumber'

export const phoneNumberUtil = PhoneNumberUtil.getInstance()

export const validatePhoneNumber = (
  countryCode: string,
  phoneNumber: string
): boolean => {
  const fullPhoneNumber = `${countryCode}${phoneNumber}`
  const phoneNo = phoneNumberUtil.parse(fullPhoneNumber)
  return phoneNumberUtil.isValidNumber(phoneNo)
}

export const getCountryCodeAndPhoneNumber = (
  phoneNo: string
): {
  countryCode: number | undefined
  phoneNumber: number | undefined
} => {
  const countryCode = phoneNumberUtil.parse(phoneNo).getCountryCode()
  const phoneNumber = phoneNumberUtil.parse(phoneNo).getNationalNumber()

  return { countryCode, phoneNumber }
}
