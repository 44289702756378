import { useState } from 'react'

import { Helmet } from 'react-helmet-async'
import { Navigate, useNavigate } from 'react-router-dom'

import SignUpConfirmationForm from 'components/forms/SignUpConfirmationForm'
import SignUpForm from 'components/forms/SignUpForm'
import { useAuth } from 'hooks/useAuth'

const Register = () => {
  const [activeForm, setActiveForm] = useState<
    'signup' | 'confirmRegistration'
  >('signup')

  const [credentials, setCredentials] = useState({ email: '', password: '' })
  const { isAuthenticated } = useAuth()
  const navigate = useNavigate()

  const handleFormChange = (form: 'signup' | 'confirmRegistration'): void => {
    setActiveForm(form)
  }

  if (isAuthenticated) return <Navigate to="/dashboard/home" replace={true} />

  return (
    <div>
      <Helmet>
        <title>Register | Haulistic</title>
      </Helmet>
      <div className="w-full flex flex-col items-center">
        <div className="mt-8 max-w-md w-full">
          {activeForm === 'signup' && (
            <SignUpForm
              onActiveForm={(form) => handleFormChange(form)}
              onSignInClick={() => navigate('/signin')}
              setSignupData={(credentials) => setCredentials(credentials)}
            />
          )}
          {activeForm === 'confirmRegistration' && (
            <SignUpConfirmationForm
              email={credentials.email}
              password={credentials.password}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default Register
