import { getToken } from 'utils/authCookies'
import { buildCustomerUrl } from 'utils/helper'

import { Order, OrderQueryParams } from './index.type'

const ordersBasePath = `${process.env.REACT_APP_API_BASE_PATH}/orders`

export const getOrders = async (
  query: OrderQueryParams
): Promise<{ total: number; orders: Order[] } | undefined> => {
  const token = await getToken()
  const url = buildCustomerUrl(ordersBasePath, query)
  try {
    const res = await fetch(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      method: 'GET',
    })

    if (res.ok) {
      const { data } = await res.json()
      return data
    }
  } catch (err) {
    console.error(err)
  }
}
