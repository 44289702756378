import React, { useState } from 'react'

import { TableCell, TableRow, TextField } from '@mui/material'

import { useAuth } from 'hooks/useAuth'
import EditButton from 'pages/dashboard/components/EditButton'
import { updateStock } from 'services/stock'

import { Variant } from './StockDetailsTable'

interface StockTableRowProps {
  variant: Variant
  setVariants: React.Dispatch<React.SetStateAction<Variant[]>>
}

const StockTableRow: React.FC<StockTableRowProps> = ({
  variant,
  setVariants,
}) => {
  const [editing, setEditing] = useState(false)
  const { organizationId } = useAuth()
  const [editedVariant, setEditedVariant] = useState<Partial<Variant>>({
    cost: variant.cost,
    quantity: variant.quantity,
  })

  const handleEditClick = () => {
    setEditing(true)
  }

  const handleSaveClick = async () => {
    if (variant.stockId && organizationId) {
      const updatedData = {
        stockId: variant.stockId,
        organizationId: organizationId,
        cost: Number(editedVariant.cost),
        quantity: Number(editedVariant.quantity),
      }

      await updateStock({ updatedStockData: updatedData })

      setVariants((prevVariants) =>
        prevVariants.map((v) =>
          v.id === variant.id
            ? {
                ...v,
                cost: String(updatedData.cost),
                quantity: updatedData.quantity,
              }
            : v
        )
      )

      setEditing(false)
    } else {
      throw new Error(' Error || undefined.')
    }
  }

  const handleChange = (field: keyof Variant, value: string | number) => {
    setEditedVariant((prev) => ({
      ...prev,
      [field]: value,
    }))
  }

  return (
    <TableRow>
      <TableCell>{variant.name}</TableCell>
      <TableCell>
        {editing ? (
          <TextField
            type="number"
            value={String(editedVariant.cost)}
            onChange={(e) => handleChange('cost', e.target.value)}
            variant="outlined"
            size="small"
          />
        ) : (
          variant.cost
        )}
      </TableCell>
      <TableCell>
        {editing ? (
          <TextField
            type="number"
            value={String(editedVariant.quantity)}
            onChange={(e) => handleChange('quantity', e.target.value)}
            variant="outlined"
            size="small"
          />
        ) : (
          variant.quantity
        )}
      </TableCell>
      <TableCell>{variant.sold}</TableCell>
      <TableCell align="right">
        {editing ? (
          <EditButton buttonText="Save" onClick={handleSaveClick} />
        ) : (
          <EditButton buttonText="Edit" onClick={handleEditClick} />
        )}
      </TableCell>
    </TableRow>
  )
}

export default StockTableRow
