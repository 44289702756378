import InfoIcon from '@mui/icons-material/Info'
import { Tooltip } from '@mui/material'
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid'

import CustomCell from 'components/table/CustomCell'

export const columns: GridColDef[] = [
  { field: 'id', headerName: 'ID', width: 90 },
  {
    field: 'title',
    headerName: 'Title',
    width: 150,
    renderCell: (params: GridRenderCellParams) => <CustomCell {...params} />,
  },
  { field: 'price', headerName: '₹ Price / Discounted Price', width: 200 },
  {
    field: 'status',
    headerName: 'Is Active / Is Listed',
    width: 200,
    renderHeader: (params) => (
      <div>
        {params.colDef.headerName}
        <Tooltip title="Shows if the product is active and if it is listed">
          <InfoIcon sx={{ ml: 1, fontSize: 16 }} />
        </Tooltip>
      </div>
    ),
  },
  { field: 'createdBy', headerName: 'Created By', width: 150 },
  {
    field: 'createdAt',
    headerName: 'Created At',
    width: 180,
  },
  {
    field: 'updatedAt',
    headerName: 'Updated At',
    width: 180,
  },
]
