import { Navigate } from 'react-router-dom'

import { useAuth } from 'hooks/useAuth'

const ProtectedRoute: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { isAuthenticated } = useAuth()

  if (!isAuthenticated) {
    // Redirect to the sign-in page if not authenticated
    return <Navigate to="/signin" replace />
  }

  return <>{children}</>
}

export default ProtectedRoute
