import { FC, useEffect, useState } from 'react'

import { Chip, Stack, Typography } from '@mui/material'

import { SubCard } from 'components/card'
import MultiSelectTags from 'components/tags/MultiSelectTags'
import { useSnackbar } from 'hooks/useSnackbar'
import SubTabHeader from 'pages/dashboard/components/SubTabHeader'
import { updateProduct } from 'services/products'

interface TagDetailsProps {
  productId: number
  tagsData?: string[]
}

const TagsDetails: FC<TagDetailsProps> = ({ productId, tagsData }) => {
  const [tags, setTags] = useState<string[]>([])
  const [edit, setEdit] = useState(false)
  const { showSnackbar } = useSnackbar()

  useEffect(() => {
    if (tagsData) setTags(tagsData)
  }, [tagsData])

  const handleEdit = async () => {
    if (edit) {
      const result = await updateProduct({
        productId,
        dataUpdate: { tags: tags },
      })
      if (result.status === 'successful' && result.data) {
        setTags(result.data.tags)
        showSnackbar('Tags updated.', 'success')
      }
    }
    setEdit(!edit)
  }

  const handleTagsChange = (tags: string[]) => {
    setTags(tags)
  }

  return (
    <div>
      <div className="mb-5">
        <SubTabHeader
          title="Tags"
          buttonText={edit ? 'Save' : 'Edit'}
          onClick={handleEdit}
        />
        <SubCard>
          {!edit &&
            (tags.length > 0 ? (
              <Stack direction="row" spacing={1} flexWrap="wrap">
                {tags.map((tag, index) => (
                  <Chip key={index} label={tag} variant="outlined" />
                ))}
              </Stack>
            ) : (
              <Typography>None</Typography>
            ))}
          {edit && (
            <MultiSelectTags tags={tags} onTagsChange={handleTagsChange} />
          )}
        </SubCard>
      </div>
    </div>
  )
}

export default TagsDetails
