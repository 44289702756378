import { useEffect, useState } from 'react'

import { useAuth } from 'hooks/useAuth'
import EmptyTabContent from 'pages/dashboard/components/EmptyTabContent'
import { TabHeader } from 'pages/dashboard/components/TabHeader'
import { getCustomers } from 'services/customers'

import CustomersList from '../components/CustomersList'

// This Page will show customers List
const CustomersPage = (): JSX.Element => {
  const [tableActive, setTableActive] = useState<boolean>(false)
  const { organizationId } = useAuth()

  useEffect(() => {
    async function get(): Promise<void> {
      const result = organizationId
        ? await getCustomers({ orgId: organizationId })
        : false
      if (result && result.total > 0) setTableActive(true)
    }
    get()
  }, [organizationId])

  return (
    <div>
      <div className="mb-5">
        {!tableActive && (
          <EmptyTabContent
            title="Customers"
            description="Manage your customers, see their order history and group
                customers into segments."
            addButtonText="Add Customer"
            importButtonText="Import Customers"
          />
        )}

        {tableActive && organizationId && (
          <>
            <TabHeader title="Customers" />
            <CustomersList orgId={organizationId} />
          </>
        )}
      </div>
    </div>
  )
}

export default CustomersPage
