import { FC } from 'react'

import { Box, Typography } from '@mui/material'

import EditButton from './EditButton'

interface SubTabHeaderProps {
  title: string
  buttonText?: string
  onClick?: () => void
}

const SubTabHeader: FC<SubTabHeaderProps> = ({
  title,
  buttonText,
  onClick,
}): JSX.Element => {
  return (
    <Box
      display={'flex'}
      alignItems={'center'}
      justifyContent={'space-between'}
    >
      <Typography sx={{ fontWeight: '700' }}>{title}</Typography>
      {buttonText && <EditButton buttonText={buttonText} onClick={onClick} />}
    </Box>
  )
}
export default SubTabHeader
