import { FC, useEffect, useState } from 'react'

import { useTheme } from '@mui/material'
import { MultiValue } from 'react-select'
import CreatableSelect from 'react-select/creatable'

import { useAuth } from 'hooks/useAuth'
import { createCategory, getCategories } from 'services/products'
import { Category } from 'types/product'

type OptionType = {
  label: string
  value: string
  id: number
}

interface CategoryInputProps {
  onChange: (ids: number[]) => void
  defaultValues?: number[]
}

const CategoryInput: FC<CategoryInputProps> = ({ onChange, defaultValues }) => {
  const { organizationId } = useAuth()
  const [categoryOptions, setCategoryOptions] = useState<
    MultiValue<OptionType>
  >([])
  const [value, setValue] = useState<MultiValue<OptionType>>()
  const theme = useTheme()

  useEffect(() => {
    const get = async (orgId: number): Promise<void> => {
      const result = await getCategories(orgId)
      if (result?.status === 'successful') {
        const optionsData = result.data.map((category: Category) => ({
          label: category.name,
          value: category.name,
          id: category.id,
        }))
        setCategoryOptions(optionsData)

        if (defaultValues && defaultValues.length > 0) {
          const values = optionsData.filter((val: OptionType) =>
            defaultValues.includes(val.id)
          )
          setValue(values)
        }
      }
    }
    if (organizationId) get(organizationId)
  }, [organizationId, defaultValues])

  const handleChange = (newValue: MultiValue<OptionType>): void => {
    setValue(newValue)
    onChange(newValue.map((val) => val.id))
  }

  const handleCreate = async (newValue: string): Promise<void> => {
    const result = organizationId
      ? await createCategory({
          name: newValue.toLowerCase(),
          organizationId: organizationId,
        })
      : undefined

    if (result && result.status === 'successful') {
      const newOption: OptionType = {
        label: newValue,
        value: newValue.toLowerCase(),
        id: result.data.id,
      }

      setCategoryOptions((prev) => [...prev, newOption])
      handleChange([...(value || []), newOption])
    }
  }

  return (
    <CreatableSelect
      name="category"
      isMulti
      options={categoryOptions}
      value={value}
      onChange={handleChange}
      onCreateOption={handleCreate}
      styles={{
        control: (base, state) => ({
          ...base,
          backgroundColor: theme.palette.background.paper,
          borderWidth: state.isFocused ? '2px' : '1px',
          borderColor: state.isFocused
            ? theme.palette.primary.main
            : 'rgba(0, 0, 0, 0.23)',
          boxShadow: 'none',
          '&:hover': {
            borderColor: state.isFocused
              ? theme.palette.primary.main
              : '#333333',
          },
        }),
        menu: (base) => ({
          ...base,
          zIndex: 2,
        }),
        option: (base, state) => ({
          ...base,
          backgroundColor: state.isSelected
            ? theme.palette.action.selected
            : state.isFocused
              ? theme.palette.action.hover
              : theme.palette.background.paper,
          color: theme.palette.text.primary,
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
          },
        }),
      }}
    />
  )
}

export default CategoryInput
