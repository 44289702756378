import { createContext, ReactNode, useState } from 'react'

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined'
import { Box, Snackbar, Typography } from '@mui/material'

import { SnackbarContextType, StatusType } from 'types/snackbar'

const defaultContext: SnackbarContextType = {
  showSnackbar: () => {},
}

export const SnackbarContext = createContext(defaultContext)

export const SnackbarProvider = ({
  children,
}: {
  children: ReactNode
}): JSX.Element => {
  const [open, setOpen] = useState<boolean>(false)
  const [message, setMessage] = useState<string>('')
  const [status, setStatus] = useState<StatusType>()

  const showSnackbar = (message: string, status: StatusType): void => {
    setMessage(message)
    setStatus(status)
    setOpen(true)
  }

  const onClose = (): void => {
    setOpen(false)
  }
  return (
    <SnackbarContext.Provider value={{ showSnackbar }}>
      <Snackbar
        open={open}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={1500}
        onClose={onClose}
      >
        <Box
          className=" py-2 px-5 flex justify-center gap-2 rounded"
          sx={{ backgroundColor: 'text.primary', color: 'white' }}
        >
          {status === 'error' && <ErrorOutlineIcon />}
          {status === 'success' && <CheckCircleOutlineIcon />}
          {status === 'info' && <InfoOutlinedIcon />}
          {status === 'warning' && <WarningAmberOutlinedIcon />}

          <Typography>{message}</Typography>
        </Box>
      </Snackbar>
      {children}
    </SnackbarContext.Provider>
  )
}
