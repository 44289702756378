import { ChangeEvent, FC, useEffect, useState } from 'react'

import { Typography } from '@mui/material'

import { SubCard } from 'components/card'
import { useSnackbar } from 'hooks/useSnackbar'
import CustomTextField from 'pages/dashboard/components/CustomTextField'
import SubTabHeader from 'pages/dashboard/components/SubTabHeader'
import { updateProduct } from 'services/products'

interface NotesDetailsProps {
  productId: number
  notesData?: string
}

const NotesDetails: FC<NotesDetailsProps> = ({ productId, notesData }) => {
  const [notes, setNotes] = useState<string>()
  const [edit, setEdit] = useState(false)
  const { showSnackbar } = useSnackbar()

  useEffect(() => {
    if (notesData) setNotes(notesData)
  }, [notesData])

  const handleNotesChange = (e: ChangeEvent<HTMLInputElement>) => {
    setNotes(e.target.value)
  }

  const handleEdit = async () => {
    if (edit) {
      if (notes?.trim() === notesData) {
        setEdit(false)
        return
      }
      const result = await updateProduct({
        productId,
        dataUpdate: { notes: notes?.trim() },
      })
      if (result.status === 'successful' && result.data) {
        setNotes(result.data.notes)
        showSnackbar('Notes updated.', 'success')
      }
    }
    setEdit(!edit)
  }

  return (
    <div>
      <div className="mb-5">
        <SubTabHeader
          title="Notes"
          buttonText={edit ? 'Save' : 'Edit'}
          onClick={handleEdit}
        />
        <SubCard>
          {!edit && (
            <Typography sx={{ fontWeight: '700' }}>
              {notes ? notes : 'None'}
            </Typography>
          )}
          {edit && (
            <CustomTextField
              id="notes"
              name="notes"
              value={notes}
              onChange={handleNotesChange}
            />
          )}
        </SubCard>
      </div>
    </div>
  )
}

export default NotesDetails
