import { FC, createRef, useState } from 'react'

import { Box, Button, TextField, Typography } from '@mui/material'
import { Formik, ErrorMessage, Form, FormikHelpers } from 'formik'
import * as Yup from 'yup'

import Label from 'components/forms/Label'
import { useAuth } from 'hooks/useAuth'
import { createOrganization } from 'services/organization'
import { getRestrictedCountriesList } from 'utils/countries'

import AddressInput, { AddressType } from './AddressInput'
import PhoneNumberInput from './PhoneNumberInput'

interface StoreCreateFormStepProps {
  handleNext: () => void
  description: string
}
interface StoreCreateFormValues {
  name: string
  email: string
  phoneNumber: string
  countryCode: string
  address: string
}

const StoreCreateFormStep: FC<StoreCreateFormStepProps> = ({
  handleNext,
  description,
}) => {
  const countriesList = getRestrictedCountriesList()
  const [addressSelect, setAddressSelect] = useState<AddressType | null>(null)
  const { changeOrganization } = useAuth()
  const initialValues: StoreCreateFormValues = {
    name: '',
    email: '',
    phoneNumber: '',
    address: '',
    countryCode: '',
  }

  const phoneRegExp = /^((\+)?(\d{1,3}))?(\d{10})$/

  const storeCreateFormSchema = Yup.object().shape({
    name: Yup.string().required('Store name is required'),
    email: Yup.string()
      .email('Enter a valid email')
      .required('Email is required'),
    phoneNumber: Yup.string()
      .matches(phoneRegExp, 'Enter a valid phone number')
      .required('Phone number is required'),
    address: Yup.string().nullable().optional(),
  })

  const handleOnChange =
    (setFieldValue: FormikHelpers<StoreCreateFormValues>['setFieldValue']) =>
    (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ): void => {
      const { name, value } = event.target
      setFieldValue(name, value)
    }

  const handleStoreSetup = async (
    values: StoreCreateFormValues
  ): Promise<void> => {
    const { name, email, phoneNumber, countryCode } = values

    const address = addressSelect
      ? {
          addressLine1: addressSelect.address1,
          city: addressSelect.city,
          state: addressSelect.state,
          country: addressSelect.country,
          pinCode: addressSelect.postalCode,
          addressUrl: addressSelect.addressUrl,
          latitude: addressSelect.latitude,
          longitude: addressSelect.longitude,
        }
      : undefined

    const result = await createOrganization({
      name,
      email,
      phoneNumber,
      countryCode,
      address,
    })

    if (result?.status === 'successful' && result.data) {
      changeOrganization(result.data.id)
    }
    handleNext()
  }

  return (
    <Box
      className="p-4 shadow rounded"
      component="div"
      sx={{ backgroundColor: '#FAFAFA' }}
    >
      <Typography variant="subtitle1" gutterBottom>
        {description}
      </Typography>
      <Formik
        initialValues={initialValues}
        validationSchema={storeCreateFormSchema}
        onSubmit={handleStoreSetup}
      >
        {({
          setFieldValue,
          values,
          handleChange,
          handleBlur,
          errors,
          touched,
        }) => (
          <Form className="w-full">
            <div>
              <Label text="Name" htmlFor="name" />
              <TextField
                type="text"
                variant="outlined"
                fullWidth
                inputProps={{ style: { backgroundColor: 'white' } }}
                size="small"
                name="name"
                value={values.name}
                onChange={handleOnChange(setFieldValue)}
                placeholder="Your store name"
                error={touched.name && Boolean(errors.name)}
                helperText={<ErrorMessage name="name" />}
              />
            </div>
            <div className="grid grid-cols-1 gap-4  md:grid-cols-2">
              <div>
                <Label text="Email" htmlFor="email" />
                <TextField
                  size="small"
                  type="text"
                  variant="outlined"
                  fullWidth
                  inputProps={{ style: { backgroundColor: 'white' } }}
                  name="email"
                  value={values.email}
                  placeholder="support@yourstorename.haulistic.io"
                  onChange={handleOnChange(setFieldValue)}
                  error={touched.email && Boolean(errors.email)}
                  helperText={<ErrorMessage name="email" />}
                />
              </div>
              <div>
                <Label text="Phone number" htmlFor="phoneNumber" />
                <PhoneNumberInput
                  countriesList={countriesList}
                  countryCodeName="countryCode"
                  phoneNumberName="phoneNumber"
                  value={{
                    countryCode: values.countryCode,
                    phoneNumber: values.phoneNumber,
                  }}
                  error={{
                    countryCode:
                      touched.countryCode && errors.countryCode
                        ? errors.countryCode
                        : '',
                    phoneNumber:
                      touched.phoneNumber && errors.phoneNumber
                        ? errors.phoneNumber
                        : '',
                  }}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={{
                    phoneNumber: errors.phoneNumber,
                  }}
                />
              </div>
            </div>
            <div className="w-full mt-2">
              <Label text="Address" htmlFor="address" />
              <AddressInput
                name="address"
                inputRef={createRef()}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.address || ''}
                onAddressSelect={(address) => {
                  setFieldValue('address', address?.address1)
                  setAddressSelect(address)
                }}
                error={touched.address && Boolean(errors.address)}
                helperText={
                  touched.address && errors.address ? errors.address : ''
                }
              />
            </div>
            <Button type="submit" variant="contained" sx={{ mt: 2 }}>
              Set up store
            </Button>
          </Form>
        )}
      </Formik>
    </Box>
  )
}

export default StoreCreateFormStep
