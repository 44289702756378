import React from 'react'

import { ThemeProvider } from '@mui/material/styles'
import { LoadScript } from '@react-google-maps/api'
import { createRoot } from 'react-dom/client'
import { load } from 'webfontloader'

import './index.css'

import App from 'App'
import theme from 'styles/muiTheme'

load({
  google: {
    families: ['Lato:400,700'],
  },
})

const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? ''
const libraries: 'places'[] = ['places']

const rootElement = document.getElementById('root')
if (rootElement) {
  const root = createRoot(rootElement)
  root.render(
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <LoadScript googleMapsApiKey={googleMapsApiKey} libraries={libraries}>
          <App>
            <></>
          </App>
        </LoadScript>
      </ThemeProvider>
    </React.StrictMode>
  )
}
