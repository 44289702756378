import { useEffect, useState } from 'react'

import { format } from 'date-fns'

import { DataGridTable } from 'pages/dashboard/components/StyledDataGrid'
import { getAllProducts } from 'services/products'
import { Product } from 'types/product'

import { columns } from './Columns'

interface ProductListProps {
  orgId: number
}

const ProductList: React.FC<ProductListProps> = ({ orgId }): JSX.Element => {
  const [list, setList] = useState<Product[]>([])

  useEffect(() => {
    async function fetchData(): Promise<void> {
      const queryOptions = {
        orgId,
      }
      const result = await getAllProducts(queryOptions)
      if (result.status === 'successful' && result.data) {
        setList(result.data)
      }
    }
    fetchData()
  }, [orgId])

  const rows = list.map((product) => ({
    id: product.id,
    title: product.name,
    price: `${product.price} / ${product.discountedPrice ?? 'N/A'}`,
    status: `${product.isActive ? 'Yes' : 'No'} / ${product.isListed ? 'Yes' : 'No'}`,
    createdBy: product.createdByUser
      ? `${product.createdByUser.firstName} ${product.createdByUser.lastName}`
      : 'N/A',
    createdAt: format(new Date(product.createdAt), 'Pp'),
    updatedAt: format(new Date(product.updatedAt), 'Pp'),
  }))

  return (
    <div className="mx-5">
      <DataGridTable
        rows={rows}
        columns={columns}
        pageSizeOptions={[25, 50, 100]}
        checkboxSelection
        disableRowSelectionOnClick
        disableColumnMenu
        pagination
        paginationMode="server"
      />
    </div>
  )
}

export default ProductList
