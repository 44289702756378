import { Helmet } from 'react-helmet-async'
import { Outlet } from 'react-router-dom'

import { useAuth } from 'hooks/useAuth'

const Customers = (): JSX.Element => {
  const { organization } = useAuth()
  return (
    <>
      <Helmet>
        <title>
          Customers
          {organization && organization.name ? ` | ${organization.name}` : ''}
        </title>
      </Helmet>
      <Outlet />
    </>
  )
}

export default Customers
