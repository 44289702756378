import { PhoneNumberUtil } from 'google-libphonenumber'
import { postcodeValidator } from 'postcode-validator'
import { z } from 'zod'

import { getCountryCode } from 'utils/countries'

const phoneNumberUtil = PhoneNumberUtil.getInstance()

export const AddressFormSchema = z
  .object({
    country: z.string().max(100).trim().optional(),
    firstName: z.string().trim().optional(),
    lastName: z.string().trim().optional(),
    addressLine1: z.string().trim().optional(),
    addressLine2: z.string().trim().optional(),
    city: z.string().trim().optional(),
    state: z.string().trim().optional(),
    pinCode: z.string().trim().optional(),
    countryCode: z.string().optional(),
    phoneNo: z.string().trim().optional(),
  })
  .refine(
    (val) => {
      if (!val.country) return true
      return val.addressLine1 && val.addressLine1.length > 0
    },
    {
      path: ['addressLine1'],
      message: '*Please select address.',
    }
  )
  .refine(
    (val) => {
      if (!val.country) return true
      return val.addressLine2 && val.addressLine2.length > 0
    },
    {
      path: ['addressLine2'],
      message: '*Apartment, suite, etc. is required.',
    }
  )
  .refine(
    (val) => {
      if (!val.country) return true
      return val.city && val.city.length > 0
    },
    {
      path: ['city'],
      message: '*City is required.',
    }
  )
  .refine(
    (val) => {
      if (!val.country) return true
      return val.state && val.state.length > 0
    },
    {
      path: ['state'],
      message: '*State is required.',
    }
  )
  .refine(
    (val) => {
      if (!val.country) return true
      return val.pinCode && val.pinCode.length > 0
    },
    {
      path: ['pinCode'],
      message: '*Pin code is required.',
    }
  )
  .refine(
    (val) => {
      //Checking phone number according to country format
      if (!val.country || (!val.countryCode && !val.phoneNo)) return true

      const fullPhoneNumber = `${val.countryCode}${val.phoneNo}`
      try {
        const phoneNumber = phoneNumberUtil.parse(fullPhoneNumber)
        return phoneNumberUtil.isValidNumber(phoneNumber)
      } catch (error) {
        return false
      }
    },
    {
      path: ['phoneNo'],
      message: '*Invalid phone number',
    }
  )
  .refine(
    (val) => {
      if (!val.country) return true
      // Checking postal code according to country format
      if (!val.pinCode) return false
      const postalCode = val.pinCode
      const postalCodeCountry = getCountryCode(val.country)
      try {
        return postalCodeCountry
          ? postcodeValidator(postalCode, postalCodeCountry)
          : false
      } catch (error) {
        return false
      }
    },
    {
      path: ['pinCode'],
      message: '*Invalid format',
    }
  )

export const CustomerFormSchema = z
  .object({
    firstName: z.string().min(1, '*First Name is required.').trim(),
    lastName: z.string().trim().optional(),
    email: z
      .string()
      .min(1, '*Email is required')
      .email('*Invalid email')
      .trim(),
    countryCode: z.string().min(1, '*Please select Country Code'),
    phoneNo: z.string().min(1, '*Please provide phone number').trim(),
    instagramIdLink: z.string().optional(),
  })
  .refine(
    (val) => {
      //Checking phone number according to country format
      const fullPhoneNumber = `${val.countryCode}${val.phoneNo}`
      try {
        const phoneNumber = phoneNumberUtil.parse(fullPhoneNumber)
        return phoneNumberUtil.isValidNumber(phoneNumber)
      } catch (error) {
        return false
      }
    },
    {
      path: ['phoneNo'],
      message: '*Invalid phone number',
    }
  )

export type CustomerFormType = z.infer<typeof CustomerFormSchema>
export type AddressFormType = z.infer<typeof AddressFormSchema>
