import { FC, useEffect, useState } from 'react'

import {
  Box,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material'

import Card from 'components/card'
import { useAuth } from 'hooks/useAuth'

import AddProductFormStep from './AddProductFormStep'
import StoreCreateFormStep from './StoreCreateFormStep'

const steps = [
  {
    label: 'Add your business details',
    description: `Enter essential information about your business, including your company name, location, and contact details.
    This information will be used to identify your business across the platform and ensure that your customers know how to reach you.`,
    component: 'CreateOrg',
  },
  // {
  //   label: 'Customize your store',
  //   description: `Tailor the appearance of your store to match your brand. Choose from a variety of themes, adjust your layout, select colors that resonate with your brand identity,
  //     and set up a welcoming homepage.`,
  // },
  // {
  //   label: 'Connect your Instagram account',
  //   description: `Link your Instagram account to seamlessly integrate your social media presence with your store.
  //   This step allows you to pull content directly from Instagram and use it to enhance product listings and drive engagement.`,
  // },
  {
    label: 'Add your first product',
    description: `Start building your inventory by adding your first product. Provide details like product names, descriptions, pricing,
    and high-quality images. Set up categories and tags to help customers navigate your offerings more easily.`,
    component: 'CreateProduct',
  },
  // {
  //   label: 'Place a test order',
  //   description: `Conduct a trial run to ensure everything in your store is working perfectly. Place an order as if you were a customer,
  //   from adding a product to the cart, checking out, to processing payment. This step helps identify any issues before you go live.`,
  // },
  {
    label: 'Setup Complete: Your Business is Ready',
    description: '',
    component: '',
  },
]

interface renderStepContentProps {
  step: {
    label: string
    description: string
    component: string
  }
  handleNext: () => void
}

const RenderStepContent: FC<renderStepContentProps> = ({
  step,
  handleNext,
}) => {
  switch (step.component) {
    case 'CreateOrg':
      return (
        <StoreCreateFormStep
          description={step.description}
          handleNext={handleNext}
        />
      )
    case 'CreateProduct':
      return <AddProductFormStep description={step.description} />
    default:
      return <Typography variant="body2">{''}</Typography>
  }
}

const CheckList = (): JSX.Element => {
  const [activeStep, setActiveStep] = useState(0)
  const { organization } = useAuth()

  useEffect(() => {
    if (organization && organization.onboardingStatus === 'PRODUCT_PENDING') {
      setActiveStep(1)
    } else if (organization && organization.onboardingStatus === 'COMPLETED') {
      setActiveStep(2)
    }
  }, [organization])

  const handleNext = (): void => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  // const handleBack = () => {
  //   setActiveStep((prevActiveStep) => prevActiveStep - 1)
  // }

  return (
    <>
      <Box ml={2}>
        <Typography variant="h6">Set up your store</Typography>
      </Box>
      <Card>
        <Box>
          <Stepper activeStep={activeStep} orientation="vertical">
            {steps.map((step, index) => (
              <Step key={index}>
                <StepLabel>
                  <Typography variant="body1">
                    <b>{step.label}</b>
                  </Typography>
                </StepLabel>
                <StepContent className="p-2">
                  <RenderStepContent step={step} handleNext={handleNext} />
                </StepContent>
              </Step>
            ))}
          </Stepper>
        </Box>
      </Card>
    </>
  )
}

export default CheckList
