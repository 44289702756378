import { getAuthCookie } from 'utils/authCookies'

import { GetStockProps, UpdatedStockProps } from './index.types'

const stockBasePath = `${process.env.REACT_APP_API_BASE_PATH}/stock`

const getAllStock = async ({ orgId }: GetStockProps) => {
  const token = getAuthCookie('IdToken')
  try {
    const res = await fetch(`${stockBasePath}?orgId=${orgId}`, {
      method: 'GET',
      headers: {
        Authorization: ` Bearer ${token}`,
      },
    })

    const { data } = await res.json()
    if (res.ok) {
      return { status: 'successful', data }
    }
    return { status: 'failed' }
  } catch (error) {
    console.error('Error getting presigned URL:', error)
    throw error
  }
}

export const updateStock = async ({ updatedStockData }: UpdatedStockProps) => {
  const token = getAuthCookie('IdToken')
  try {
    const { stockId, organizationId, cost, quantity } = updatedStockData

    const response = await fetch(`${stockBasePath}/${stockId}`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        cost: Number(cost),
        organizationId: organizationId,
        quantity: Number(quantity),
      }),
    })

    if (!response.ok) {
      throw new Error('Failed to update stock')
    }
  } catch (error) {
    console.error('Error ', error)
  }
}

export { getAllStock }
