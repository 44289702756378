import { FC } from 'react'

import { useFormikContext } from 'formik'

import Preview from './Preview'
import { ProductDetailFormValues } from '../pages/AddProductPage'

interface CreateProductPreviewProps {
  variantAdd?: boolean
  files?: string[]
}

const CreateProductPreview: FC<CreateProductPreviewProps> = ({ files }) => {
  const { values } = useFormikContext<ProductDetailFormValues>()

  const PreviewData = {
    name: values.name,
    variants: values.variants,
    price: values.price,
    discountedPrice: values.discountedPrice,
    description: values.description,
  }

  return <Preview files={files} product={PreviewData} />
}
export default CreateProductPreview
