import React, { useEffect, useState } from 'react'

import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import FiberManualRecordOutlinedIcon from '@mui/icons-material/FiberManualRecordOutlined'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import { IconButton } from '@mui/material'

interface CarouselProps {
  files: string[]
  interval?: number
}

interface CarouselIndicatorsProps {
  images: string[]
  activeIndex: number
  onClick: (index: number) => void
}
const fileUrl = `${process.env.REACT_APP_FILE_URL}`

const CarouselIndicators: React.FC<CarouselIndicatorsProps> = ({
  images,
  activeIndex,
  onClick,
}): JSX.Element => {
  return (
    <div className=" flex justify-center items-center">
      <div>
        {images.map((_, index) => (
          <IconButton
            key={index}
            size="small"
            onClick={() => onClick(index)}
            sx={{ fontSize: '0.5rem', mr: '0rem' }}
          >
            {activeIndex === index ? (
              <FiberManualRecordIcon fontSize="inherit" />
            ) : (
              <FiberManualRecordOutlinedIcon fontSize="inherit" />
            )}
          </IconButton>
        ))}
      </div>
    </div>
  )
}

const Carousel: React.FC<CarouselProps> = ({ files, interval = 3000 }) => {
  const [activeIndex, setActiveIndex] = useState(0)
  const [images, setImages] = useState<string[]>([])

  useEffect(() => {
    setImages(files)
    setActiveIndex(0)
  }, [files])

  const nextSlide = (): void => {
    setActiveIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    )
  }
  const prevSlide = (): void => {
    setActiveIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    )
  }

  const goToSlide = (index: number): void => {
    setActiveIndex(index)
  }

  useEffect(() => {
    const autoPlayInterval = setInterval(() => {
      setActiveIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      )
    }, interval)
    return (): void => {
      clearInterval(autoPlayInterval)
    }
  }, [interval, images.length])

  return (
    <div className=" relative max-w-full overflow-hidden">
      <div className=" absolute left-0 top-1/2 -translate-y-1/2">
        <IconButton onClick={prevSlide}>
          <KeyboardArrowLeftIcon />
        </IconButton>
      </div>
      <img
        src={`${fileUrl}/${images[activeIndex]}`}
        alt={`Slide ${activeIndex}`}
        className=" w-full h-80 block object-contain"
      />

      <div className=" absolute right-0 top-1/2 -translate-y-1/2">
        <IconButton onClick={nextSlide}>
          <KeyboardArrowRightIcon />
        </IconButton>
      </div>
      <CarouselIndicators
        images={images}
        activeIndex={activeIndex}
        onClick={goToSlide}
      />
    </div>
  )
}

export default Carousel
