import {
  getGridDateOperators,
  getGridNumericOperators,
  getGridStringOperators,
  GridColDef,
} from '@mui/x-data-grid'
import { format } from 'date-fns'

import CustomCell from 'components/table/CustomCell'

export const columns: GridColDef[] = [
  {
    field: 'id',
    headerName: 'Id',
    width: 70,
    type: 'number',
    headerAlign: 'left',
    align: 'left',
    resizable: false,
    filterOperators: getGridNumericOperators().filter(
      (operator) => operator.value == '='
    ),
  },
  {
    field: 'fullName',
    headerName: 'Name',
    hideable: false,
    width: 120,
    renderCell: (params) => <CustomCell {...params} />,
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value == 'contains'
    ),
  },
  {
    field: 'email',
    width: 150,
    headerName: 'Email',
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value == 'contains'
    ),
  },
  {
    field: 'address',
    width: 200,
    headerName: 'Address',
    sortable: false,
    filterable: false,
  },
  {
    field: 'createdAt',
    width: 180,
    headerName: 'Date Created',
    type: 'dateTime',
    valueFormatter: (value): string => {
      return format(new Date(value), 'MMMM d, yyyy, h:mm a')
    },
    filterOperators: getGridDateOperators(true).filter(
      (operator) =>
        operator.value == 'is' ||
        operator.value == 'after' ||
        operator.value == 'before' ||
        operator.value == 'onOrAfter' ||
        operator.value == 'onOrBefore'
    ),
  },
  {
    field: 'phoneNumber',
    width: 150,
    type: 'number',
    headerName: 'Phone Number',
    sortable: false,
    filterOperators: getGridNumericOperators().filter(
      (operator) => operator.value == '='
    ),
  },
  {
    field: 'amountSpent',
    width: 120,
    type: 'number',
    headerName: 'Amount Spent',
    sortable: false,
    filterable: false,
    valueFormatter: (value: number): number => {
      return value ?? 0
    },
    filterOperators: getGridNumericOperators().filter(
      (operator) =>
        operator.value == '>' ||
        operator.value === '<' ||
        operator.value == '=' ||
        operator.value == '>=' ||
        operator.value == '<='
    ),
  },
]
