import React from 'react'

import SearchIcon from '@mui/icons-material/Search'
import InputAdornment from '@mui/material/InputAdornment'
import TextField from '@mui/material/TextField'

import theme from 'styles/muiTheme'

const SearchFilter: React.FC = () => {
  return (
    <TextField
      variant="standard"
      placeholder="Search Products"
      fullWidth
      sx={{
        backgroundColor: theme.palette.secondary.main,
        py: 1.5,
        px: 1,
        borderRadius: 1,
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon sx={{ color: 'black' }} />
          </InputAdornment>
        ),
      }}
    />
  )
}

export default SearchFilter
