import { useEffect, useState } from 'react'

import { useAuth } from 'hooks/useAuth'
import SetupStorePrompt from 'pages/dashboard/components/SetupStorePrompt'
import { TabHeader } from 'pages/dashboard/components/TabHeader'

// This Page will show customers List
const AnalyticsPage = (): JSX.Element => {
  const [tableActive, setTableActive] = useState<boolean>(false)
  const { organizationId, organization } = useAuth()

  useEffect(() => {
    async function get(): Promise<void> {
      const result = organizationId ? null : false
      if (result && result > 0) setTableActive(true)
    }
    get()
  }, [organizationId])

  if (organization == null) {
    return <SetupStorePrompt />
  }

  return (
    <div>
      <div className="mb-5">
        {tableActive && organizationId && (
          <>
            <TabHeader title="Analytics" />
            {/* <AnalyticsList orgId={organizationId} /> */}
          </>
        )}
      </div>
    </div>
  )
}

export default AnalyticsPage
